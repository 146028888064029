import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
//@ts-ignore
import {ReactComponent as Nitrogen} from '../../assets/icone_azote.svg';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean;
}

const NitrogenIcon: React.FC<HeaderIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={Nitrogen}
      style={{fillOpacity: props.selected ? '1' : '0.5'}}
      viewBox='0 0 16 16'
    />
  );
};

export default NitrogenIcon;
