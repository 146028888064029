import {Reducer} from 'redux';
import {
  CLEAR_WATER_BALANCE_DATA,
  COMPARE_FIELDS_SUCCESS,
  GET_PDF_WATERBALANCE,
  LOAD_WATER_BALANCE_SUCCESS,
  RESET_WATERBALANCE_DATA,
  SAVE_SUMMARY_IRRIGATION_INFO,
  SET_SELECTED_COMPARE_NDVI_DATE,
  SET_SELECTED_NDVI_DATE,
  UPDATE_PDF_WATERBALANCE_STATUS,
  WaterBalanceActionTypes,
} from '../actions/WaterBalanceActions';
import WaterBalanceState from '../models/WaterBalanceState';

const initialState: WaterBalanceState = {
  summaryIrrigationInfo: null,
  data: {
    ET: [],
    fc: [],
    kcb: [],
    ndvi: [],
    SWC1: [],
    SWC2: [],
    SWC3: [],
    GDD: [],
    TAW: [],
    RAW: [],
    DE: [],
    GDD_phase1_min: [],
    GDD_phase1_max: [],
    GDD_phase6_min: [],
    GDD_phase6_max: [],
    ETO: [],
    pluie: [],
    GDD_phase8_min: [],
    irrigation: [],
    GDD_phase8_max: [],
    irrigauto: [],
    GDD_stages: [],
    fcover_verde: [],
    lai_verde: [],
  },
  selectedNdviDate: null,
  selectedCompareNdviDate: null,
  vegetationDataChart: [],
  irrigationDataChart: [],
  phenoMainStages: [],
  vegetationCompareFieldDataChart: null,
};
const waterBalanceReducers: Reducer<
  WaterBalanceState,
  WaterBalanceActionTypes
> = (state = initialState, action: WaterBalanceActionTypes) => {
  switch (action.type) {
    case GET_PDF_WATERBALANCE:
      return {
        ...state,
        sending_pdf: 'sending',
      };
    case UPDATE_PDF_WATERBALANCE_STATUS:
      return {
        ...state,
        sending_pdf: action.payload,
      };
    case RESET_WATERBALANCE_DATA:
      return {
        ...state,
        summaryIrrigationInfo: null,
        data: initialState.data,
        selectedNdviDate: null,
        selectedCompareNdviDate: null,
        vegetationDataChart: [],
        irrigationDataChart: [],
        phenoMainStages: [],
        vegetationCompareFieldDataChart: null,
      };
    case COMPARE_FIELDS_SUCCESS:
      return {
        ...state,
        vegetationCompareFieldDataChart: action.payload,
      };
    case SAVE_SUMMARY_IRRIGATION_INFO:
      return {
        ...state,
        summaryIrrigationInfo: action.payload,
      };
    case LOAD_WATER_BALANCE_SUCCESS:
      return {
        ...action.payload,
      };
    case SET_SELECTED_NDVI_DATE:
      return {
        ...state,
        selectedNdviDate: action.payload,
      };
    case SET_SELECTED_COMPARE_NDVI_DATE:
      return {
        ...state,
        selectedCompareNdviDate: action.payload,
      };
    case CLEAR_WATER_BALANCE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default waterBalanceReducers;
