export const adminReportTranslationsFr = {
  'admin.report.raw_data.title': 'Données brutes',
  'admin.report.vizualization.title': 'Visualisation',
  'admin.report.path.report_not_found': 'Rapport non trouvé',
  'admin.report.modal.title': 'Rapport',
  'admin.report.status.success': 'Succès',
  'admin.report.status.sent': 'Envoyé',
  'admin.report.status.confirmed': 'Confirmé',
  'admin.report.status.sending': 'Envoi en cours',
  'admin.report.status.failed': 'Echec',
  'admin.report.status.error': 'Erreur',
  'admin.report.status.pending': 'En attente',
  'admin.report.status.processing': 'En cours',
  'admin.report.status.unknown': 'Inconnu',
  'admin.report.back_to_list': 'Retour à la liste',
  'admin.report.title': 'Rapport',
  'admin.report.notifications.status': 'Statut',
  'admin.report.notifications.notification_channel': 'Canal de notification',
  'admin.report.notifications.created_at': 'Date de création',
  'admin.report.notifications.updated_at': 'Date de mise à jour',
  'admin.report.notifications.sended_at': 'Date d’envoi',
  'admin.report.notifications.title': 'Notifications',
  'admin.report.notifications.user': 'Utilisateur',
  'admin.report.notifications.recipient': 'Destinataire',
};
