import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as week_arrow_top} from '../../assets/week_arrow_top.svg';

const WeekArrowTop: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={week_arrow_top} viewBox='0 0 220 219' />
  );
};

export default WeekArrowTop;
