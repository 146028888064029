export enum PixagriApplication {
    APP = "pixagri-app",
    API = "pixagri-api",
    REPORT_ORCHESTRATOR_API = "report-orchestrator-api",
    REPORT_WORKER = "report-worker-api",
    NOTIFICATION_API = "notification-api",
    WAGO_API = "wago-api",
    CLOUD_FUNCTIONS = "cloud_functions",
    PROCESSING_ORCHESTRATOR_API = "processing-orchestrator-api",
}
export default PixagriApplication;