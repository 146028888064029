import TerranisBusinessLines from '../models/TerranisBusinessLines';

let terranisBusinessLine: TerranisBusinessLines | null = null;

export function setBusinessLine(businessLine: TerranisBusinessLines) {
    terranisBusinessLine = businessLine;
}

export function getBusinessLine() {
    return terranisBusinessLine;
}
