import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_right_top} from '../../assets/arrow_right_top.svg';

const ArrowRightTop: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={arrow_right_top} viewBox='0 0 117 118' />
  );
};

export default ArrowRightTop;
