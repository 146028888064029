import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
//@ts-ignore
import {ReactComponent as IrrigationIconTab} from '../../assets/irrigationIcon.svg';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean;
}

const IrrigationIcon: React.FC<HeaderIconProps> = (props: HeaderIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={IrrigationIconTab}
      style={{fillOpacity: props.selected ? '1' : '0.5'}}
      viewBox='0 0 13 16'
    />
  );
};

export default IrrigationIcon;
