import {Box, Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
// @ts-ignore
import weather_image from '../assets/weather_image.png';
import WeatherArrowTop from './icons/WeatherArrowTop';

const WeatherOverviewHelp: React.FC = () => {
  return (
    <Box flex={1} width={1} display='flex' flexDirection={'column'}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.weather.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={weather_image} alt='weather_board' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-85}
            left={250}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.weather.board' />
              </Typography>
              <WeatherArrowTop
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 0,
                  left: -80,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WeatherOverviewHelp;
