import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import ArrowLeftMiddle from './icons/ArrowLeftMiddle';
import ArrowRightBottom from './icons/ArrowRightBottom';
// @ts-ignore
import fieldComparisonImage from '../assets/fieldComparisonImage.png';
import ArrowTopRight from './icons/ArrowTopRight';

const VegetationCompareFieldHelp: React.FC = () => {
  const theme = useTheme();

  return (
    <Box flex={1} width={1} display='flex' flexDirection={'column'}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.vegetation.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={fieldComparisonImage} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-45}
            right={-20}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.vegetation.compare_fields.selector' />
              </Typography>
              <ArrowTopRight
                sx={{
                  fontSize: '170px',
                  position: 'absolute',
                  top: 0,
                  right: 150,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-50}
            right={-200}>
            <Box width={'170px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.vegetation.compare_field_abscissa_selector' />
              </Typography>
              <ArrowRightBottom
                sx={{
                  fontSize: '80px',
                  position: 'absolute',
                  top: -55,
                  right: 120,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-180}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.select_field' />
              </Typography>
              <ArrowLeftMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -50,
                  right: -90,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Typography
          width='600px'
          variant='caption'
          color={theme.palette.grey[600]}
          paddingY={4}
          textAlign='center'>
          <FormattedMessage id='info.vegetation.compare_fields.explanation' />
        </Typography>
      </Box>
    </Box>
  );
};

export default VegetationCompareFieldHelp;
