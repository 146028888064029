import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_bottom_right} from '../../../assets/arrows/cropsSoils/arrow_bottom_right.svg';

const ArrowBottomRight: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={arrow_bottom_right} viewBox='0 0 107 114' />
  );
};

export default ArrowBottomRight;
