import {Box, Typography} from '@mui/material';
import {AuthUser} from 'modules/auth/models/AuthUser';
import {getCropTranslationKeyFromCode} from 'modules/crops/services/CropsTranslation';
import Field from 'modules/fields/models/Field';
import {isFieldSubscribe} from 'modules/fields/services/FieldsService';
import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import {ServiceId} from 'shared/models/RouterParam';
import {AppState} from 'shared/store';
import {
  getHydroStressMessageId,
  getNdviHomogeneityMessageId,
} from '../services/WaterBalanceService';
interface WaterBalanceMapTooltipProps {
  field: Field;
  campaignId: number;
  user: AuthUser | null;
}
/**
 * Tooltip use on fieldMap for service irrigation & vegetation
 * @param props
 * @returns
 */
const WaterBalanceMapTooltip: React.FC<WaterBalanceMapTooltipProps> = (
  props,
) => {
  const {field, campaignId} = props;
  const intl = useIntl();
  const {selectedService} = useSelector<AppState, AppState['fields']>(
    (state) => state.fields,
  );
  const rotation =
    field && field.irrigation && field.irrigation.length
      ? field.irrigation.find((rot) => rot.campaign_id === campaignId)
      : null;
  const vegetation =
    field && field.vegetation && field.vegetation.length
      ? field.vegetation.find((veg) => veg.campaign_id === campaignId)
      : null;

  if (field && rotation && selectedService) {
    return (
      <>
        <Box display='flex' flexDirection='row'>
          {rotation && (
            <Typography color='textSecondary'>
              {rotation.crop_code
                ? intl.formatMessage({
                    id: getCropTranslationKeyFromCode(rotation.crop_code)!,
                  })
                : rotation.crop_name}
            </Typography>
          )}
        </Box>

        {selectedService === ServiceId.IRRIGATION && (
          <Box mt={3}>
            <Typography color='textSecondary'>
              <IntlMessages id='waterBalance.SWC2Risk.label' />
            </Typography>
            {
              //TODO : color
            }
            {rotation &&
            rotation.RAW &&
            rotation.DE &&
            isFieldSubscribe(selectedService, campaignId, field) ? (
              <Typography variant='body2' color='textPrimary'>
                <IntlMessages
                  id={getHydroStressMessageId(
                    rotation.RAWC?.most_recent_value ?? null,
                    field.field_thresholds,
                  )}
                />
              </Typography>
            ) : (
              <Typography variant='body2' color='textPrimary'>
                <IntlMessages id='common.notAvailable' />
              </Typography>
            )}
          </Box>
        )}

        {selectedService === ServiceId.VEGETATION && (
          <Box mt={3}>
            <Typography color='textSecondary'>
              <IntlMessages id='waterBalance.NdviHomogeneity.label' />
            </Typography>
            {vegetation &&
            vegetation.fcover &&
            vegetation.fcover.most_recent_value_std &&
            isFieldSubscribe(selectedService, campaignId, field) ? (
              <Typography variant='body2' color='textPrimary'>
                <IntlMessages
                  id={getNdviHomogeneityMessageId(
                    vegetation.fcover.most_recent_value_std.toFixed(2),
                  )}
                />
              </Typography>
            ) : (
              <Typography variant='body2' color='textPrimary'>
                <IntlMessages id='common.notAvailable' />
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  }
  return null;
};

export default WaterBalanceMapTooltip;
