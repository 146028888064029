import {enUS} from '@mui/material/locale';
import {adminReportTranslationsEn} from 'modules/admin/report/translations/AdminReportTranslationsEn';
import {cropsTranslationsEn} from 'modules/crops/translations/CropsTranslationsEn';
import {enReportTranslations} from 'modules/report/translations/EnReportTranslations';
import {soilsTranslationsEn} from 'modules/soils/translations/SoilsTranslationsEn';
import enMessages from '../locales/en_US.json';

const EnLang = {
  messages: {
    ...enMessages,
    ...cropsTranslationsEn,
    ...soilsTranslationsEn,
    ...enReportTranslations,
    ...adminReportTranslationsEn,
  },
  muiLocale: enUS,
  locale: 'en-US',
};
export default EnLang;
