import React from 'react';
import HeaderButton from 'shared/components/layout/HeaderButton';
import useServiceSelected from 'shared/hooks/useServiceSelected';
import {ServiceId} from 'shared/models/RouterParam';
import AdminIcon from './AdminIcon';

const AdminHeaderButton: React.FC = () => {
  const selected = useServiceSelected(ServiceId.ADMIN);

  return (
    <>
      <HeaderButton
        path={'/admin/report/report_list'}
        icon={<AdminIcon selected={selected} />}
        selected={selected}
        serviceId={ServiceId.ADMIN}
      />
    </>
  );
};

export default AdminHeaderButton;
