export const adminReportTranslationsRu = {
  'admin.report.raw_data.title': 'Сырые данные',
  'admin.report.vizualization.title': 'Визуализация',
  'admin.report.path.report_not_found': 'Отчет не найден',
  'admin.report.modal.title': 'Отчет',
  'admin.report.status.success': 'Успех',
  'admin.report.status.sent': 'Отправлено',
  'admin.report.status.confirmed': 'Подтверждено',
  'admin.report.status.sending': 'Отправка',
  'admin.report.status.failed': 'Неудача',
  'admin.report.status.error': 'Ошибка',
  'admin.report.status.pending': 'В ожидании',
  'admin.report.status.processing': 'Обрабатывается',
  'admin.report.status.unknown': 'Неизвестно',
  'admin.report.back_to_list': 'Вернуться к списку',
  'admin.report.title': 'Отчет',
  'admin.report.notifications.status': 'Статус',
  'admin.report.notifications.notification_channel': 'Канал уведомлений',
  'admin.report.notifications.created_at': 'Дата создания',
  'admin.report.notifications.updated_at': 'Дата обновления',
  'admin.report.notifications.sended_at': 'Дата отправки',
  'admin.report.notifications.title': 'Уведомления',
  'admin.report.notifications.user': 'Пользователь',
  'admin.report.notifications.recipient': 'Получатель',
};
