import {ruRU} from '@mui/material/locale';
import {adminReportTranslationsRu} from 'modules/admin/report/translations/AdminReportTranslationsRu';
import {ruReportTranslations} from 'modules/report/translations/RuReportTranslations';
import ruMessages from '../locales/ru_RU.json';

const RuLang = {
  messages: {
    ...ruMessages,
    ...ruReportTranslations,
    ...adminReportTranslationsRu,
  },
  muiLocale: ruRU,
  locale: 'ru-RU',
};
export default RuLang;
