import {ReportConfiguration} from '../models/ReportConfigurationModel';
import ReportJob from '../models/ReportJob';
import ReportJobType from '../models/ReportJobType';

export const LOAD_REPORTS_CONFIG = 'LOAD_REPORTS_CONFIG';
export interface LoadReportsConfigAction {
  type: typeof LOAD_REPORTS_CONFIG;
}

export const LOAD_REPORTS_CONFIG_SUCCESS = 'LOAD_REPORTS_CONFIG_SUCCESS';
export interface LoadReportsConfigSuccessAction {
  type: typeof LOAD_REPORTS_CONFIG_SUCCESS;
  payload: {[key: string]: ReportConfiguration};
}

export const LOAD_REPORTS = 'LOAD_REPORTS';
export interface LoadReportsAction {
  type: typeof LOAD_REPORTS;
  payload: {
    campaignId: number;
    type: ReportJobType;
  };
}

export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export interface LoadReportsSuccessAction {
  type: typeof LOAD_REPORTS_SUCCESS;
  payload: {[key: string]: ReportJob};
}

export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';
export interface SetSelectedReportAction {
  type: typeof SET_SELECTED_REPORT;
  payload: ReportJob;
}

export const RETRY_REPORT_GENERATION = 'RETRY_REPORT_GENERATION';
export interface RetryReportGenerationAction {
  type: typeof RETRY_REPORT_GENERATION;
  payload: {
    reportId: string;
  };
}

export const GENERATE_NOTIFICATION = 'GENERATE_NOTIFICATION';
export interface GenerateNotificationAction {
  type: typeof GENERATE_NOTIFICATION;
  payload: {
    reportJob: ReportJob;
  };
}

export type ReportActionTypes =
  | LoadReportsConfigAction
  | LoadReportsConfigSuccessAction
  | LoadReportsAction
  | LoadReportsSuccessAction
  | SetSelectedReportAction
  | RetryReportGenerationAction
  | GenerateNotificationAction;

export const sharedReportActions = {
  generateNotification: (reportJob: ReportJob): GenerateNotificationAction => ({
    type: GENERATE_NOTIFICATION,
    payload: {reportJob},
  }),
  retryReportGeneration: (reportId: string): RetryReportGenerationAction => ({
    type: RETRY_REPORT_GENERATION,
    payload: {reportId},
  }),
  loadReportsConfig: (): LoadReportsConfigAction => ({
    type: LOAD_REPORTS_CONFIG,
  }),

  loadReportsConfigsSuccess: (config: {
    [key: string]: ReportConfiguration;
  }): LoadReportsConfigSuccessAction => ({
    type: LOAD_REPORTS_CONFIG_SUCCESS,
    payload: config,
  }),

  loadReports: (
    campaignId: number,
    reportType: ReportJobType,
  ): LoadReportsAction => ({
    type: LOAD_REPORTS,
    payload: {campaignId, type: reportType},
  }),

  loadReportsSuccess: (reports: {
    [key: string]: ReportJob;
  }): LoadReportsSuccessAction => ({
    type: LOAD_REPORTS_SUCCESS,
    payload: reports,
  }),

  setSelectedReport: (report: ReportJob) => ({
    type: SET_SELECTED_REPORT,
    payload: report,
  }),
};

export default sharedReportActions;
