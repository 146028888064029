import React from 'react';
// @ts-ignore
import PixagriIcon from '../../assets/images/pixagriLogo.svg';

//interface AppLogoProps extends ElementProps {}

const AppLogo: React.FC<any> = (props: any) => {
  return <img src={PixagriIcon} alt='logo-pixagri' {...props} width='150px' />;
};

export default AppLogo;
