import {connectRouter, routerMiddleware} from 'connected-react-router';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import createSagaMiddleware, {SagaMiddleware} from 'redux-saga';
import thunk from 'redux-thunk';
import {activateAuthInterceptor} from '../../modules/auth/services/AuthService';
import reducers from '../reducers';
import {rootSagas} from '../sagas/RootSagas';

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory();
const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore(initialState?: AppState) {
  // Configure the middleware
  // TODO: remove thunk
  const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, thunk, routerMiddleware(history)];

  const enhancers: any[] = [];
  const windowIfDefined =
    typeof window === 'undefined' ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );
  activateAuthInterceptor(store);

  // Activate the sagas (need to be done after store creation)
  sagaMiddleware.run(rootSagas);

  return store;
}

export {history};

// export type AppState = ReturnType<typeof rootReducer>;
