import {makeStyles} from '@mui/styles';
import {useContext} from 'react';
import AppContext from '../../../@crema/utility/AppContext';
import {ThemeStyle} from '../../configs/AppEnums';
import AppContextPropsType, {
  CremaTheme,
} from '../../models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  const {themeStyle} = useContext<AppContextPropsType>(AppContext);
  return {
    appMain: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      paddingTop: 56,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 70,
      },
      '&.appMainFixedFooter': {
        paddingBottom: 48,
        [theme.breakpoints.up('xl')]: {
          paddingBottom: 58,
        },
        '& .scrollAppSidebar': {
          height:
            themeStyle === ThemeStyle.MODERN
              ? 'calc(100vh - 260px) !important'
              : 'calc(100vh - 198px) !important',
          [theme.breakpoints.up('xl')]: {
            height:
              themeStyle === ThemeStyle.MODERN
                ? 'calc(100vh - 300px) !important'
                : 'calc(100vh - 236px) !important',
          },
        },
        '& .mainContainer': {
          paddingBottom: 1,
        },
      },
    },
    mainContent: {
      flex: 1,
      display: 'flex',
    },
    mainContainerFull: {
      width: '100vw',
      '& > .scrollbar-container': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    boxedLayout: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1260,
        marginLeft: 'auto',
        marginRight: 'auto',
        // boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
        '& .app-sidebar': {
          position: 'absolute',
        },
        '& $mainContent': {
          width: 'calc(100% - 19rem)',
          flex: 'auto',
        },
        '& .mainContainer': {
          width: '100%',
        },
        '& .app-bar': {
          width: '100%',
          position: 'absolute',
          // boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
        },
        '& .grid-btn': {
          fontSize: 11,
        },
        '& .app-header-fixed': {
          position: 'absolute',
        },
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1680,
        '& $mainContent': {
          width: 'calc(100% - 21.6rem)',
        },
      },
    },
  };
});
export default useStyles;
