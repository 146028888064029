import IntlMessages from '@crema/utility/IntlMessages';
import {Box, Typography} from '@mui/material';
import React from 'react';
import ArrowSoilsIcon from 'shared/components/Icons/ArrowSoils';
import useStyles from '../../Informations.style';
// @ts-ignore
import subscriptionImage from '../assets/images/subscriptionImage.png';
const Subscriptions: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      width='100%'
      id='subscriptionInfo'>
      <Typography variant='h4' className={classes.dialogSubtitle}>
        <IntlMessages id='informations.subscriptions.title' />
      </Typography>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexGrow={1}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width={'40%'}>
          <Typography
            className={[classes.textBold, classes.subscriptionText].join(' ')}>
            <IntlMessages id='informations.subscriptions.text.free_trial' />
          </Typography>
          <Typography
            className={[classes.textBold, classes.subscriptionText].join(' ')}>
            <IntlMessages id='informations.subscriptions.text.processus' />
          </Typography>
          <Typography
            variant='subtitle1'
            className={[classes.textBold, classes.subscriptionText].join(' ')}>
            <IntlMessages id='informations.subscriptions.text.start_with' />
          </Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          flexGrow={1}
          className={classes.positionRelative}>
          <Box display='flex' width={'50%'} className={classes.arrowAndText}>
            <IntlMessages id='informations.subscriptions.our_subscriptions' />
            <ArrowSoilsIcon
              color='primary'
              fontSize='large'
              classes={{fontSizeLarge: classes.svgArrowMedium}}
            />
          </Box>
          {/** TODO: Do a screenshot, image not good */}
          <img src={subscriptionImage} width='450px' alt='subscriptions' />
        </Box>
      </Box>
    </Box>
  );
};
export default Subscriptions;
