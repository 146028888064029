import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
//@ts-ignore
import {ReactComponent as ActiveSubscription} from '../../assets/active_subscription.svg';

const ActiveSubscriptionIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps,
) => {
  return (
    <SvgIcon {...props} component={ActiveSubscription} viewBox='0 0 83 100' />
  );
};

export default ActiveSubscriptionIcon;
