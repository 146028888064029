import {getAppEnv} from 'shared/configs/SetAppEnvConfig';
import {ErrorWithCode} from 'shared/models/HttpError';
import PixagriApplication from 'shared/models/PixagriApplications';
import {ImageGetPixelValueResponse} from '../models/ImageGetPixelValueResponse';
import apiService from './ApiService';
import {getErrorWithCode} from './LogService';

export class ImageApiService {
  /**
   * Titiler is used to read pixels in image & display it on map
   * TODO: to be moved in another service ?
   * @returns
   */
  getTitilerRootApiUrl = () => {
    const titilerHost = apiService.getApiUrl(
      PixagriApplication.TITILER,
      getAppEnv(),
    );
    return titilerHost;
  };

  getImagePixelValue = async (
    imageUrl: string,
    lon: number | string,
    lat: number | string,
  ): Promise<{
    pixelValue?: ImageGetPixelValueResponse;
    error?: ErrorWithCode;
  }> => {
    const result: {
      pixelValue?: ImageGetPixelValueResponse;
      error?: ErrorWithCode;
    } = {};
    try {
      const url = `${this.getTitilerRootApiUrl()}/cog/point/${lon},${lat}?url=${imageUrl}`;
      const response = await fetch(url);
      const responseData = await response.json();

      if (!response.ok) {
        result.error = {
          message: "Can't get pixel value",
        };
      } else {
        result.pixelValue = responseData;
      }
    } catch (error: any) {
      console.error(error);
      result.error = getErrorWithCode(error);
    }
    return result;
  };
}
const imageApiService = new ImageApiService();
export default imageApiService;
