import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
//@ts-ignore
import {ReactComponent as NoSubscription} from '../../assets/no_subscription.svg';

const NoSubscriptionIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return <SvgIcon {...props} component={NoSubscription} viewBox='0 0 83 100' />;
};

export default NoSubscriptionIcon;
