import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import {AxiosResponse} from 'axios';
import {Feature, FeatureCollection, Polygon} from 'geojson';
import {AuthState} from 'modules/auth/models/AuthState';
import Campaign from 'modules/campaigns/models/Campaign';
import CampaignsState from 'modules/campaigns/models/CampaignsState';
import {getCurrentCampaignBis} from 'modules/campaigns/services/CampaignsService';
import {getCarbonFlowLevel} from 'modules/carbon/services/CarbonGaugeService';
import {dashboardRoutePath} from 'modules/dashboards/configs/DashboardRoutePaths';
import nitrogenActions from 'modules/nitrogen/actions/NitrogenActions';
import PixagriSubscription from 'modules/subscriptions/models/PixagriSubscription';
import SubscriptionsState from 'modules/subscriptions/models/SubscriptionsState';
import {
  getServiceRole,
  isSubscriptionValid,
} from 'modules/subscriptions/services/isFieldSubscribe';
import {HydroStressLevel} from 'modules/waterBalance/models/HydroStressLevel';
import {getHydroStressLevel} from 'modules/waterBalance/services/WaterBalanceService';
import {
  PutEffect,
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  redirectTo,
} from 'shared/actions/Common';
import {timeoutDuration} from 'shared/configs/AppConst';
import {ServiceId} from 'shared/models/RouterParam';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store/index';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import wellknown from 'wellknown';
import api, {pixagriApiUrl} from '../../../shared/services/ApiConfig';
import getCropFromNameMatch from '../../crops/configs/CropIconsConfig';
import {actions as rotationActions} from '../../rotations/actions/RotationsActions';
import actions, {
  ADD_NEW_FIELD,
  AddNewFieldAction,
  DELETE_FIELD,
  DeleteFieldAction,
  IMPORT_FIELDS,
  ImportFieldsAction,
  LOAD_FIELDS_SUMMARY,
  LoadFieldsSummaryAction,
  MODIFY_FIELD,
  ModifyFieldAction,
  ON_SELECT_FILTERS,
  OnSelectFiltersAction,
} from '../actions/FieldsActions';
import {
  CURRENTLY_SUBSCRIBE_ERROR,
  NO_CAMPAIGN_FIELD_KEY,
} from '../configs/FieldConfigs';
import {getServiceOverviewFullRoute} from '../configs/FieldsRoutePaths';
import {FieldForm} from '../models/AddField';
import Field from '../models/Field';
import FieldCreation from '../models/FieldCreation';
import {FieldFilterPropertyKey} from '../models/FieldFilters';
import {FieldIrrigationService, FieldTesting} from '../models/FieldTesting';
import FieldsState from '../models/FieldsState';

function getUserFieldsRequest() {
  const fieldsSummaryUrl = `${pixagriApiUrl}/fields`;
  return api.get(fieldsSummaryUrl);
}

function getFarmerslistRequest() {
  const farmerListUrl = `${pixagriApiUrl}/users/farmers`;
  return api.get(farmerListUrl);
}

function addToCrops(crops: string[], cropName: string) {
  if (!crops.includes(cropName)) {
    crops.push(cropName);
  }
}

//Sends api request to Redux store
function* loadFieldsSummary(action: LoadFieldsSummaryAction) {
  // retrieve intl only now to be sure correctly initialized
  const intl = appIntl();

  try {
    yield put(fetchStart('loadFieldsSummary'));
    const res: AxiosResponse = yield call(getUserFieldsRequest);
    let fieldsSummary = res.data as FieldTesting[];

    if (!fieldsSummary) {
      fieldsSummary = [];
    }

    const fieldsCount = fieldsSummary.length;

    // Get CampaignState
    const {data}: CampaignsState = yield select(
      (state: AppState) => state.campaigns,
    );
    const {rolesConfig}: AuthState = yield select(
      (state: AppState) => state.auth,
    );

    const {thresholds} = yield select((state: AppState) => state.carbon);

    const {selectedFieldId} = yield select((state: AppState) => state.fields);
    const campaigns: Campaign[] = Object.values(data);
    let currentCampaignId: number | null = null;
    if (campaigns) {
      currentCampaignId = getCurrentCampaignBis(campaigns);
    }

    // Get the subscription
    const subscriptionState: SubscriptionsState = yield select(
      (state: AppState) => state.subscriptions,
    );
    const pixagriSubscriptions = subscriptionState.pixagriData;

    let selectedCampaignId = action.payload.selectedCampaignId;
    //let selectedFieldId = action.payload.selectedFieldId;
    // let user = action.payload.user;

    const fieldsMap: {[key: string]: {[key: string]: FieldTesting}} = {};
    const crops: string[] = [];

    let featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    const farmerListRes: AxiosResponse = yield call(getFarmerslistRequest);

    const farmerList = farmerListRes.data as FieldsState['farmersList'];

    // Compute data for fields and get farmers
    const farmers: string[] = [];
    fieldsSummary.forEach((field) => {
      if (!farmers.includes(field.farmer)) {
        farmers.push(field.farmer);
      }
    });

    // Compute Carbon flow level for service carbon
    if (thresholds) {
      fieldsSummary.forEach((field) => {
        if (field.carbon && field.carbon.length) {
          field.carbon.forEach((period) => {
            const level = getCarbonFlowLevel(
              period.carbon_net_flux,
              thresholds,
            );
            if (level) {
              period.carbon_flow_level = level;
            }
          });
        }
      });
    } else {
      log.error(`Cannot define Carbon flow level`);
    }

    // Get different crop from service irrigation and compute hydroStress level
    fieldsSummary.forEach((field) => {
      if (field.irrigation.length) {
        field.irrigation.forEach((rotation: FieldIrrigationService) => {
          // Push crops in map if not exist
          rotation.field_id = field.field_id;
          rotation.crop_type = getCropFromNameMatch(rotation.crop_name);

          const cropName = rotation.crop_name;
          addToCrops(crops, cropName);

          // Compute hydrostress level

          if (rotation.RAW && rotation.TAW) {
            rotation.hydro_stress_level = getHydroStressLevel(
              rotation.RAWC?.most_recent_value!,
              //rotation.DE?.most_recent_value,
              field.field_thresholds,
            );
          } else {
            rotation.hydro_stress_level = HydroStressLevel.UNDEFINED;
          }
        });
      }
    });
    // Get different crop for  service vegetation
    fieldsSummary.forEach((field) => {
      if (field.vegetation && field.vegetation.length) {
        field.vegetation.forEach((veg) => {
          // Push crops in map if not exist
          const cropName = veg.crop_name;
          addToCrops(crops, cropName);
        });
      }
    });

    // Compute Bbox
    fieldsSummary.forEach((field) => {
      field.geojson_geometry = wellknown.parse(field.geometry) as Polygon;

      if (field.geojson_geometry) {
        const feature: Feature = {
          type: 'Feature',
          id: field.field_id,
          geometry: field.geojson_geometry,
          properties: {},
        };

        // Add it to the list of features in order to compute bbox
        featureCollection.features.push(feature);
        field.bbox = bbox(feature);
        field.centroid = centroid(feature).geometry.coordinates;

        if (field.vegetation.length) {
          field.vegetation.forEach((veg) => {
            let ndvi_bbox: number[] = [];
            if (veg.fcover && veg.fcover.pic_bbox) {
              try {
                ndvi_bbox = JSON.parse(veg.fcover.pic_bbox);
              } catch (error: any) {
                log.error(
                  `Error while parsing ndvi_pic_bbox for field ${field.field_id}`,
                );
              }
            }

            veg.ndvi_coordinates = [
              [ndvi_bbox[0], ndvi_bbox[3]],
              [ndvi_bbox[2], ndvi_bbox[3]],
              [ndvi_bbox[2], ndvi_bbox[1]],
              [ndvi_bbox[0], ndvi_bbox[1]],
            ];
          });
        }
      }
    });

    if (pixagriSubscriptions) {
      // Affect subscription
      fieldsSummary.forEach((field) => {
        field.subscriptions.weather = field.subscriptions.irrigation;
        const fieldActiveRoles: string[] = [];
        const fieldActivePermission: string[] = [];
        const serviceSubscriptions = Object.keys(field.subscriptions);
        serviceSubscriptions.forEach((key: string) => {
          const service = key as keyof Field['subscriptions'];
          const newSubscriptionList: PixagriSubscription[] = [];
          const subscriptionList = field.subscriptions[service];
          if (subscriptionList && subscriptionList.length) {
            subscriptionList.forEach((subscription) => {
              const subId = subscription.sub_id;
              if (subId) {
                let pixagriSubscription: PixagriSubscription | null = null;
                if (pixagriSubscriptions[subId]) {
                  pixagriSubscription = pixagriSubscriptions[subId];
                } else {
                  log.error(
                    `${subId} not found in ${pixagriSubscriptions} for service ${service} for field: ${field.field_id}`,
                  );
                  pixagriSubscription = subscription;
                }

                if (!pixagriSubscriptions[subId]) {
                  log.error(`Pixagrisubscription ${subId} not found`);
                }
                newSubscriptionList.push(pixagriSubscription);

                if (
                  pixagriSubscription.roles &&
                  pixagriSubscription.roles.length &&
                  isSubscriptionValid(pixagriSubscription)
                ) {
                  if (
                    pixagriSubscription.roles.includes(
                      getServiceRole(ServiceId.IRRIGATION),
                    ) &&
                    !pixagriSubscription.roles.includes(
                      getServiceRole(ServiceId.WEATHER),
                    )
                  ) {
                    pixagriSubscription.roles.push(
                      getServiceRole(ServiceId.WEATHER),
                    );
                  }
                  fieldActiveRoles.push(...pixagriSubscription.roles);
                }
              }
            });
          } else {
            log.debug(`No subscription for service ${key}`);
          }
          field.subscriptions[service] = newSubscriptionList;
        });
        if (fieldActiveRoles && fieldActiveRoles.length && rolesConfig) {
          log.debug(
            `Found ${fieldActiveRoles.length} role, start getting permissions`,
          );
          fieldActiveRoles.forEach((role) => {
            const roleConfig = rolesConfig[role];
            if (roleConfig) {
              fieldActivePermission.push(...roleConfig.permissions);
            } else {
              log.error(`role ${role} not found in rolesConfig`);
            }
          });
        }
        log.debug(
          `Field subscriptions hold ${fieldActivePermission.length} permissions`,
        );
        field.active_permissions = fieldActivePermission;
      });
    }

    // sorted field by campaign
    fieldsSummary.forEach((field) => {
      if (field.carbon && field.carbon.length) {
        field.carbon.forEach((carb) => {
          if (!fieldsMap[carb.campaign_id]) {
            fieldsMap[carb.campaign_id] = {};
          }
          if (!fieldsMap[carb.campaign_id][field.field_id]) {
            fieldsMap[carb.campaign_id][field.field_id] = field;
          }
        });
      }
      if (field.irrigation && field.irrigation.length) {
        field.irrigation.forEach((rotation) => {
          if (!fieldsMap[rotation.campaign_id]) {
            fieldsMap[rotation.campaign_id] = {};
          }

          if (!fieldsMap[rotation.campaign_id][field.field_id]) {
            fieldsMap[rotation.campaign_id][field.field_id] = field;
          }
        });
      }
      if (field.vegetation && field.vegetation.length) {
        field.vegetation.forEach((veg) => {
          if (!fieldsMap[veg.campaign_id]) {
            fieldsMap[veg.campaign_id] = {};
          }

          if (!fieldsMap[veg.campaign_id][field.field_id]) {
            fieldsMap[veg.campaign_id][field.field_id] = field;
          }
        });
      }
      if (field.nitrogen && field.nitrogen.length) {
        field.nitrogen.forEach((cover) => {
          if (!fieldsMap[cover.campaign_id]) {
            fieldsMap[cover.campaign_id] = {};
          }
          if (!fieldsMap[cover.campaign_id][field.field_id]) {
            fieldsMap[cover.campaign_id][field.field_id] = field;
          }
        });
      } else {
        const unAffectedCampaign = currentCampaignId
          ? currentCampaignId
          : NO_CAMPAIGN_FIELD_KEY;
        field.no_campaign_affected = true;
        if (!fieldsMap[unAffectedCampaign]) {
          fieldsMap[unAffectedCampaign] = {};
        }
        fieldsMap[unAffectedCampaign][field.field_id] = field;
      }
    });

    const allFeaturesBbox = bbox(featureCollection);
    const fieldsState: FieldsState = {
      count: fieldsCount,
      data: fieldsMap,
      fieldsList: fieldsSummary,
      campaigns: campaigns,
      crops: crops,
      farmersList: farmerList,
      farmers: farmers,
      selectedService: null,
      selectedFieldId: selectedFieldId,
      selectedCampaign: selectedCampaignId,
      zoomToSelected: false,
      mapPosition: {
        latitude: 0,
        longitude: 0,
        zoom: 3,
      },
      bbox: allFeaturesBbox,
      mapDisplayNdvi: false,
    };

    yield put(actions.loadFieldsSummarySuccess(fieldsState));
    yield put(fetchSuccess('loadFieldsSummary'));
    /*
    let selectedField: FieldTesting | null = null;

    if (selectedFieldId && selectedCampaignId) {
      selectedField = fieldsMap[selectedCampaignId][selectedFieldId];
    }

    if (!selectedFieldId || (selectedFieldId && selectedField)) {
      console.log('select field saga 343', selectedFieldId);
      yield put(
        actions.onSelectField(selectedFieldId, action.payload.zoomToSelected),
      );
    } else {
      yield put(
        fetchError(
          'loadFieldsSummary',
          intl.formatMessage({id: 'fields.errors.errorFieldNotFound'}),
          getCampaignFieldsPath(selectedCampaignId),
        ),
      );
    }*/
  } catch (error: any) {
    log.error(`Error loading fields: ${error.message}`, error);
    yield put(
      fetchError(
        'loadFieldsSummary',
        intl.formatMessage({id: 'fields.errors.errorLoading'}),
      ),
    );
  }
}

/**
 *
 * @param action Load the water balance data when a field is selected
 */
/* Function temporary disable, waiting for implementation choice
function* selectField(action: OnSelectFieldAction) {
  if (action.payload.selectedFieldId) {

    if(action.payload.serviceId && action.payload.serviceId === ServiceId.IRRIGATION) {

      const { data, selectedCampaign }: FieldsState = yield select((state: AppState) => state.fields)

      if(selectedCampaign && data) {

        const fieldSelected = data[selectedCampaign][action.payload.selectedFieldId]
        const rotationSelected = fieldSelected.irrigation.find(rotation => rotation.campaign_id === selectedCampaign);

        if(rotationSelected) {

          yield put(
            waterBalanceActions.loadWaterBalance(
              action.payload.selectedFieldId,
              rotationSelected.id_rotation,
              ),
              );
            }
          }
        }
  }
}
*/
function* selectFilters(action: OnSelectFiltersAction) {
  const {selectedFilters} = action.payload;
  log.debug('Applying filter');
  log.debug(selectedFilters);
  const {
    fieldsList,
    selectedCampaign,
    selectedService,
    selectedFieldId,
  }: FieldsState = yield select((state: AppState) => state.fields);
  const fieldsMap: {[key: string]: Field} = {};
  fieldsList.forEach((field) => {
    fieldsMap[field.field_id] = field;
  });
  if (selectedFilters) {
    const multiPropsFilter = (): Field[] => {
      const filtersKeys = Object.keys(
        selectedFilters,
      ) as FieldFilterPropertyKey[];
      if (fieldsList && fieldsList.length) {
        let fieldsFiltered: Field[] = [];

        fieldsList.forEach((field) => {
          // loop for use beacaus it can be break when the first filter is found for one field
          let fieldMatch = true;

          for (let i = 0; i < filtersKeys.length; i++) {
            const keyName = filtersKeys[i];

            if (
              keyName === FieldFilterPropertyKey.CROP_NAME &&
              selectedFilters[keyName].length
            ) {
              // crop can be found in irrigation or vegetation
              const irrigationFound = field.irrigation.find(
                (rotation) =>
                  selectedFilters[keyName].includes(rotation.crop_name) &&
                  rotation.campaign_id === selectedCampaign,
              );
              const vegetationFound = field.vegetation.find(
                (vegetation) =>
                  selectedFilters[keyName].includes(vegetation.crop_name) &&
                  vegetation.campaign_id === selectedCampaign,
              );
              if (
                (irrigationFound || vegetationFound) &&
                !fieldsFiltered.find(
                  (fieldInTab) => fieldInTab.field_id === field.field_id,
                )
              ) {
              } else {
                fieldMatch = false;
              }
            }

            if (
              keyName === FieldFilterPropertyKey.FARMER &&
              selectedFilters[keyName].length
            ) {
              if (
                selectedFilters[keyName].includes(field.farmer) &&
                !fieldsFiltered.find(
                  (fieldInTab) => fieldInTab.field_id === field.field_id,
                )
              ) {
              } else {
                fieldMatch = false;
              }
            }
          }
          if (fieldMatch) {
            fieldsFiltered.push(field);
          }
        });

        return fieldsFiltered;
      }
      return [];
    };
    let filteredElements = multiPropsFilter();
    const itemdAdded = filteredElements.map((element) => element.field_id);
    log.debug(`Filtered fields count: ${itemdAdded?.length}`);
    let featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    itemdAdded.forEach((fieldId) => {
      const item = fieldsMap[fieldId];
      if (item.geojson_geometry) {
        const feature: Feature = {
          type: 'Feature',
          id: item.field_id,
          geometry: item.geojson_geometry,
          properties: {},
        };
        // Add it to the list of features in order to compute bbox
        featureCollection.features.push(feature);
      }
    });
    const filteredFeaturesBbox = bbox(featureCollection);

    yield put(
      actions.onSelectFiltersSuccess(
        selectedFilters,
        itemdAdded,
        filteredFeaturesBbox,
        action.payload.zoomToSelected,
      ),
    );

    // Clear selection if needed
    if (selectedFieldId && !itemdAdded.includes(selectedFieldId)) {
      log.debug(
        `Currently selected field is not part of the selection after applying filter, redirecting`,
      );
      if (selectedCampaign && selectedService) {
        yield put(
          redirectTo(
            getServiceOverviewFullRoute(selectedCampaign, selectedService),
          ),
        );
      } else {
        yield put(redirectTo(dashboardRoutePath));
      }
    }
  } else {
    log.info('Campaign or filter not defined');
  }
}

function addNewFieldRequest(newField: FieldForm) {
  const fieldUrl = `${pixagriApiUrl}/fields`;
  return api.post(fieldUrl, newField);
}

function* importFields(action: ImportFieldsAction) {
  // retrieve intl only now to be sure correctly initialized
  const intl = appIntl();
  try {
    if (
      action.payload.featureCollection &&
      action.payload.featureCollection.features &&
      action.payload.featureCollection.features.length > 0
    ) {
      yield put(fetchStart('importFields'));
      let fieldTemplate: FieldCreation = action.payload.template;
      const {selectedService} = yield select((state: AppState) => state.fields);
      const creationRequests: PutEffect<any>[] = [];
      const today = new Date();
      if (selectedService) {
        action.payload.featureCollection.features.forEach((feature, index) => {
          // Make a copy to be sure
          const newField = {...fieldTemplate};
          // Extract the wkt from the geojson
          // Convert to wkt
          newField.geometry = wellknown.stringify(
            feature.geometry as wellknown.GeoJSONPolygon,
          );

          // Build the name
          if (feature.properties?.name || feature.properties?.Name) {
            newField.field_name =
              feature.properties.name || feature.properties?.Name;
          } else {
            newField.field_name = intl.formatMessage(
              {id: 'fields.import.default_name_template'},
              {number: index + 1, importDate: today},
            );
          }

          log.debug(newField);
          creationRequests.push(
            put(actions.addNewField(newField, selectedService)),
          );
          //creationRequests.push(call(addNewFieldRequest, newField));
        });
      }
      yield all(creationRequests);
      yield put(fetchSuccess('importFields'));

      yield call(reloadFieldsSummaryAfterDelay, 2);
    } else {
      log.warn('Missing some information for fields import');
    }
  } catch (error: any) {
    log.error(`Error while importing fields`, error);
    const message = intl.formatMessage({
      id: 'fields.import.fields_creation_error',
    });
    yield put(fetchError('importFields', message));
  }
}

function* addNewField(action: AddNewFieldAction) {
  try {
    yield put(fetchStart('addNewField'));
    let {field, service} = action.payload;
    const {cover, rotation, station_id, ...otherProps} = field;
    const newField = {
      ...otherProps,
      connected_station_ids: station_id ? [station_id] : [],
    };
    const res = yield call(addNewFieldRequest, newField as FieldForm);
    const createdField = res.data as {field_id: number; campaign_id: number};

    if (createdField && createdField.field_id) {
      log.info(
        `Field created with id ${createdField.field_id} for campaign ${createdField.campaign_id}`,
      );

      switch (service) {
        case ServiceId.IRRIGATION:
          if (rotation) {
            yield put(
              rotationActions.addNewRotation(createdField.field_id, rotation),
            );
            break;
          } else {
            log.error(`Cannot post rotation: no data to post`);
            break;
          }
        case ServiceId.VEGETATION:
          if (rotation) {
            yield put(
              rotationActions.addNewRotation(createdField.field_id, rotation),
            );
            break;
          } else {
            log.error(`Cannot post rotation: no data to post`);
            break;
          }
        case ServiceId.NITROGEN:
          if (cover) {
            yield put(nitrogenActions.addCover(createdField.field_id, cover));
            break;
          } else {
            log.error(`Cannot post cover: no data to post`);
            break;
          }
        default:
          break;
      }
    }

    yield put(fetchSuccess('addNewField'));

    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error: any) {
    yield put(fetchError('addNewField', error.message));
  }
}

export function* reloadFieldsSummaryAfterDelay(delayMultiplier: number = 1) {
  // Wait for some times
  yield put(fetchStart('reloadFieldsSummaryAfterDelay'));
  yield delay(timeoutDuration * delayMultiplier);
  yield put(fetchSuccess('reloadFieldsSummaryAfterDelay'));

  // Reload fields
  const {user} = yield select((state: AppState) => state.auth);
  const {selectedCampaign} = yield select((state: AppState) => state.fields);
  const action: LoadFieldsSummaryAction = {
    type: LOAD_FIELDS_SUMMARY,
    payload: {
      selectedFieldId: null,
      zoomToSelected: true,
      selectedCampaignId: selectedCampaign,
      user: user,
    },
  };
  yield call(loadFieldsSummary, action);
}

function modifyFieldRequest(field: FieldForm): Promise<AxiosResponse<any>> {
  const fieldUrl = `${pixagriApiUrl}/fields/${field.field_id}`;
  const {field_id, ...values} = field;
  return api.put(fieldUrl, values);
}

function* modifyField(action: ModifyFieldAction) {
  try {
    yield put(fetchStart('modifyField'));
    const {field, service} = action.payload;
    const {cover, rotation, station_id, ...otherProps} = field;
    const newField = {
      ...otherProps,
      connected_station_ids: station_id ? [station_id] : [],
    };

    if (!otherProps.field_id) {
      throw Error(`Cannot update field with id ${otherProps.field_id}`);
    }
    const {field_id} = newField;
    //TODO: Fix type
    const res: AxiosResponse<any> = yield call(
      modifyFieldRequest as any,
      newField,
    );
    if (res.status < 300) {
      log.info(`field ${field_id} updated`);

      switch (service) {
        case ServiceId.VEGETATION:
        case ServiceId.IRRIGATION:
          if (rotation) {
            yield put(rotationActions.modifyRotation(field_id!, rotation));
            break;
          } else {
            log.error(`Cannot update rotation: no data to post`);
            break;
          }
        case ServiceId.NITROGEN:
          if (cover) {
            yield put(nitrogenActions.updateCover(field_id!, cover));
            break;
          } else {
            log.error(`Cannot update cover: no data to post`);
            break;
          }
        default:
          break;
      }
    } else {
      throw Error(`Field not updated, Api response with status ${res.status}`);
    }
    yield put(fetchSuccess('modifyField'));
    yield call(reloadFieldsSummaryAfterDelay);
  } catch (error: any) {
    yield put(fetchError('modifyField', error.message));
  }
}

function deleteFieldRequest(selectedField: number) {
  const fieldUrl = `${pixagriApiUrl}/fields/${selectedField}`;
  return api.delete(fieldUrl);
}

function* deleteField(action: DeleteFieldAction) {
  try {
    yield put(fetchStart('deleteField'));
    yield call(deleteFieldRequest, action.payload);
    yield put(fetchSuccess('deleteField'));

    // Redirect to campaign
    const {selectedCampaign, selectedService} = yield select(
      (state: AppState) => state.fields,
    );
    yield put(
      redirectTo(
        getServiceOverviewFullRoute(selectedCampaign, selectedService),
      ),
    );

    yield call(reloadFieldsSummaryAfterDelay);
  } catch (error: any) {
    const {data} = error.response;
    if (data && data.error && data.error === CURRENTLY_SUBSCRIBE_ERROR) {
      yield put(
        fetchError(
          'deleteField',
          appIntl().formatMessage({
            id: 'field.delete.error.cuurently_subscribe',
          }),
        ),
      );
    } else {
      yield put(fetchError('deleteField', error.message));
    }
  }
}

export function* fieldsSagas() {
  yield all([
    takeEvery(LOAD_FIELDS_SUMMARY, loadFieldsSummary),
    // takeEvery(ON_SELECT_FIELD, selectField),
    takeEvery(ON_SELECT_FILTERS, selectFilters),
    takeEvery(ADD_NEW_FIELD, addNewField),
    takeEvery(IMPORT_FIELDS, importFields),
    takeEvery(MODIFY_FIELD, modifyField),
    takeEvery(DELETE_FIELD, deleteField),
  ]);
}
