export const adminReportTranslationsUz = {
  'admin.report.raw_data.title': "Xom ma'lumotlar",
  'admin.report.vizualization.title': 'Vizualizatsiya',
  'admin.report.path.report_not_found': 'Hisobot topilmadi',
  'admin.report.modal.title': 'Hisobot',
  'admin.report.status.success': 'Muvaffaqiyat',
  'admin.report.status.sent': 'Yuborildi',
  'admin.report.status.confirmed': 'Tasdiqlandi',
  'admin.report.status.sending': 'Yuborilmoqda',
  'admin.report.status.failed': 'Muvaffaqiyatsiz',
  'admin.report.status.error': 'Xatolik',
  'admin.report.status.pending': 'Kutilmoqda',
  'admin.report.status.processing': 'Qayta ishlanmoqda',
  'admin.report.status.unknown': "Noma'lum",
  'admin.report.back_to_list': 'Ro‘yxatga qaytish',
  'admin.report.title': 'Hisobot',
  'admin.report.notifications.status': 'Holat',
  'admin.report.notifications.notification_channel': 'Xabarnoma kanali',
  'admin.report.notifications.created_at': 'Yaratilgan sana',
  'admin.report.notifications.updated_at': 'Yangilangan sana',
  'admin.report.notifications.sended_at': 'Yuborilgan sana',
  'admin.report.notifications.title': 'Xabarnomalar',
  'admin.report.notifications.user': 'Foydalanuvchi',
  'admin.report.notifications.recipient': 'Qabul qiluvchi',
};
