import * as FirebaseFirestore from '@firebase/firestore-types';
import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {AxiosResponse} from 'axios';
import {delay} from 'redux-saga/effects';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions';
import api, {pixagriApiUrl} from 'shared/services/ApiConfig';
import log from 'shared/services/LogService';
import rsf from 'shared/services/ReduxSagaFirebase';
import {AppState} from 'shared/store';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import Stripe from 'stripe';
import {actions as fieldsAction} from '../../fields/actions/FieldsActions';
import {
  LOAD_INVOICES_INFO,
  LOAD_SUBSCRIPTIONS_LISTING,
  LoadInvoicesInfoAction,
  LoadSubscriptionsListingAction,
  subscriptionActions,
} from '../actions/SubscriptionsActions';
import PixagriSubscription from '../models/PixagriSubscription';
import Subscription from '../models/Subscription';
import {SelectedSubscriptionState} from '../models/SubscriptionsState';

function getSubscriptionsRequest(): Promise<
  AxiosResponse<PixagriSubscription[]>
> {
  const subsriptionsUrl = `${pixagriApiUrl}/subscriptions`;
  return api.get(subsriptionsUrl);
}

function* loadSubscriptions(action: LoadSubscriptionsListingAction) {
  try {
    const {user} = yield select((state: AppState) => state.auth);
    const uid = user.uid;

    yield put(fetchStart('loadSubscriptions'));

    if (action.payload.delay) {
      yield delay(action.payload.delay);
    }

    // Fetch from pixagri
    let pixagriSubscriptions: {[key: number]: PixagriSubscription} = {};
    const pixagriSubscriptionsResp: AxiosResponse<PixagriSubscription[]> =
      yield call(getSubscriptionsRequest);
    if (pixagriSubscriptionsResp && pixagriSubscriptionsResp.data) {
      const pixagriSubscriptionsList = pixagriSubscriptionsResp.data;
      pixagriSubscriptionsList.forEach((pixagriSubscription) => {
        if (pixagriSubscription.sub_id) {
          pixagriSubscriptions[pixagriSubscription.sub_id] =
            pixagriSubscription;
        }
      });
    }

    // Fetch from firebase
    const subscriptionsSnapShot: FirebaseFirestore.QuerySnapshot = yield call(
      rsf.firestore.getCollection,
      `customers/${uid}/subscriptions`,
    );
    let subscriptions: {[key: string]: Subscription} = {};
    subscriptionsSnapShot.forEach((data) => {
      const subscriptionData = data.data() as Subscription;
      subscriptionData.id = data.id;
      subscriptions[data.id] = subscriptionData;
    });

    yield put(
      subscriptionActions.loadSubscriptionsSuccess(
        subscriptions,
        pixagriSubscriptions,
      ),
    );
    yield put(fetchSuccess('loadSubscriptions'));

    yield put(fieldsAction.loadFieldsSummary(null, true, null, user));
  } catch (error: any) {
    log.error(error);
    yield put(fetchError('loadSubscriptions', error.message));
  }
}

function* loadInvoicesInfoSaga(action: LoadInvoicesInfoAction) {
  const subscriptionId = action.payload;
  try {
    yield put(fetchStart('loadInvoicesInfoSaga'));
    const {user} = yield select((state: AppState) => state.auth);
    const uid = user.uid;

    const invoicesSnapshot: FirebaseFirestore.QuerySnapshot = yield call(
      rsf.firestore.getCollection,
      `customers/${uid}/subscriptions/${subscriptionId}/invoices`,
    );
    const selectedSubscription: SelectedSubscriptionState = {
      subscriptionId: subscriptionId,
      invoices: {} as {[key: string]: Stripe.Invoice},
    };
    invoicesSnapshot.forEach((snap) => {
      const invoiceId = snap.id as string;
      const invoiceData = snap.data() as Stripe.Invoice;
      selectedSubscription.invoices = {
        ...selectedSubscription.invoices,
        [invoiceId]: invoiceData,
      };
    });

    yield put(
      subscriptionActions.loadInvoicesInfoSuccess(selectedSubscription),
    );
    yield put(fetchSuccess('loadInvoicesInfoSaga'));
  } catch (error: any) {
    log.error(
      `Error fetching invoices for subscription ${subscriptionId}`,
      error,
    );
    yield put(
      fetchError(
        'loadInvoicesInfoSaga',
        appIntl().formatMessage({id: 'subscriptions.load_invoices_error'}),
      ),
    );
  }
}

export function* subscriptionsSaga() {
  yield all([
    takeEvery(LOAD_SUBSCRIPTIONS_LISTING, loadSubscriptions),
    takeEvery(LOAD_INVOICES_INFO, loadInvoicesInfoSaga),
  ]);
}
