import {DateTime} from 'luxon';
import {extractDateFromTimestampOrString} from '../../core/services/FirestoreService';
import {logger} from '../../core/services/LogService';
import {NotificationModel} from '../models/NotificationModel';
import NotificationStatus from '../models/NotificationStatus';
import notificationApiService from './NotificationApiService';

class SharedNotificationsService {
  getUnreadNotificationsOfProducer(
    notificationList: NotificationModel[],
    producer_ref?: string | null,
  ) {
    const unReadNotifications = notificationList.filter(
      (notification) => notification.status !== NotificationStatus.CONFIRMED,
    );
    const unReadNotificationsOfProducer = unReadNotifications.filter(
      (notification) =>
        (producer_ref && notification.producer_ref === producer_ref) ||
        !notification.producer_ref,
    );
    logger.info(
      `Found ${unReadNotificationsOfProducer.length} unread notification for producer ${producer_ref}`,
    );
    return unReadNotificationsOfProducer;
  }
  getUnreadNotificationsExcludingProducer(
    notificationList: NotificationModel[],
    producer_ref?: string | null,
  ) {
    const unReadNotifications = notificationList.filter(
      (notification) => notification.status !== NotificationStatus.CONFIRMED,
    );
    const unReadNotificationsExcludeProducer = unReadNotifications.filter(
      (notification) =>
        (producer_ref && notification.producer_ref !== producer_ref) ||
        !notification.producer_ref,
    );
    logger.info(
      `Found ${unReadNotificationsExcludeProducer.length} unread notification for producer different of ${producer_ref}`,
    );
    return unReadNotificationsExcludeProducer;
  }

  async getUserNotifications(
    token: string,
    status?: NotificationStatus[],
  ): Promise<{[notification_id: string]: NotificationModel}> {
    logger.info(`Getting user notifications with status ${status}`);
    const notificationsList = await notificationApiService.getUserNotifications(
      token,
      status,
    );
    logger.info(`Found ${notificationsList.length} notifications`);
    const notifications: {[notification_id: string]: NotificationModel} = {};
    notificationsList.forEach((notification) => {
      //const newNotification = this.extractDateFromNotification(notification)
      notifications[notification.id] = notification;
    });
    return notifications;
  }

  extractDateFromNotification(
    notification: NotificationModel,
  ): NotificationModel {
    const newNotification = {...notification};
    const updatedAt = extractDateFromTimestampOrString(notification.updated_at);
    if (updatedAt) {
      newNotification.updated_at = updatedAt;
    }
    const createdAt = extractDateFromTimestampOrString(notification.created_at);
    if (createdAt) {
      newNotification.created_at = createdAt;
    }
    return newNotification;
  }

  sortNotificationsByDate(notifications: NotificationModel[]) {
    return notifications.sort((a, b) => {
      return (
        DateTime.fromJSDate(new Date(b.created_at)).toMillis() -
        DateTime.fromJSDate(new Date(a.created_at)).toMillis()
      );
    });
  }
}

const sharedNotificationsService = new SharedNotificationsService();
export default sharedNotificationsService;
