import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
//@ts-ignore;
import {ReactComponent as CanceledSubscription} from '../../assets/canceled_subscription.svg';

const CanceledSubscriptionIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps,
) => {
  return (
    <SvgIcon {...props} component={CanceledSubscription} viewBox='0 0 83 100' />
  );
};

export default CanceledSubscriptionIcon;
