import * as FirebaseAuth from '@firebase/auth-types';
import {
  DocumentData,
  FirebaseFirestore,
  QuerySnapshot,
  SetOptions,
  Timestamp,
} from '@firebase/firestore-types';
import {FirebaseAuthenticationErrorCodes} from '../models/FirebaseAuthenticationErrorCodes';
import {getCurrentUserResponse} from '../models/GetCurrentUser';
import {WhereQuery} from '../models/WhereQuery';

let firestore: FirebaseFirestore;
let auth: FirebaseAuth.FirebaseAuth;
let messaging: any;

export function extractDateFromTimestampOrString(date?: Date): Date | null {
  if (!date) {
    return null;
  }
  const timeStamp = date as unknown as Timestamp;
  const dateJS = new Date(date);

  if (timeStamp && typeof timeStamp.toDate === 'function') {
    return timeStamp.toDate();
  } else if (dateJS.toString() !== 'Invalid Date') {
    return dateJS;
  } else {
    return null;
  }
}

export const setMessaging = (messagingInstance: any) => {
  messaging = messagingInstance;
};

export const getMessaging = () => {
  return messaging;
};
export const setFirestore = (firestoreInstance: FirebaseFirestore) => {
  console.log('setFirestore instance');

  firestore = firestoreInstance;
};

export const getFirestore = (): FirebaseFirestore => {
  return firestore;
};

export const setFirebaseAuth = (
  firebaseAuthInstance: FirebaseAuth.FirebaseAuth,
) => {
  console.log('setAuth instance');

  auth = firebaseAuthInstance;
};

export const getAuth = (): FirebaseAuth.FirebaseAuth => {
  return auth;
};

export const getFirebaseAuthErrorId = (
  firebaseErrorCode: FirebaseAuthenticationErrorCodes,
): string | null => {
  switch (firebaseErrorCode) {
    case FirebaseAuthenticationErrorCodes.EMAIL_ALREADY_IN_USE:
      return 'firebase.auth.error.email_already_in_use';
    case FirebaseAuthenticationErrorCodes.INVALID_EMAIL:
      return 'firebase.auth.error.invalid_email_or_password';
    case FirebaseAuthenticationErrorCodes.OPERATION_NOT_ALLOWED:
      return 'firebase.auth.error.operation_not_allowed';
    case FirebaseAuthenticationErrorCodes.USER_DISABLE:
      return 'firebase.auth.error.user_disable';
    case FirebaseAuthenticationErrorCodes.USER_NOT_FOUND:
      return 'firebase.auth.error.user_not_found';
    case FirebaseAuthenticationErrorCodes.WEAK_PASSWORD:
      return 'firebase.auth.error.weak_password';
    case FirebaseAuthenticationErrorCodes.WRONG_PASSWORD:
      return 'firebase.auth.error.invalid_email_or_password';
    default:
      return null;
  }
};
class FirestoreAuthService {
  async logIn(
    email: string,
    password: string,
  ): Promise<FirebaseAuth.UserCredential> {
    const userCredentials = await getAuth().signInWithEmailAndPassword(
      email,
      password,
    );
    return userCredentials;
  }
  async createUser(
    email: string,
    password: string,
  ): Promise<FirebaseAuth.UserCredential> {
    const userCredentials = await getAuth().createUserWithEmailAndPassword(
      email,
      password,
    );
    return userCredentials;
  }
  async updateUser(user: FirebaseAuth.User, data: any): Promise<void> {
    await user.updateProfile(data);
  }
  async sendRecoverPasswordEmail(email: string): Promise<void> {
    await getAuth().sendPasswordResetEmail(email);
  }
  async logOut(): Promise<void> {
    await getAuth().signOut();
  }

  getCurrentUser(): Promise<getCurrentUserResponse | null> {
    return new Promise<getCurrentUserResponse | null>((resolve, reject) => {
      const unsubscribe = getAuth().onAuthStateChanged(
        async (userAuth: FirebaseAuth.User | null) => {
          if (userAuth) {
            unsubscribe();
            try {
              const tokenResult = await userAuth.getIdTokenResult();
              resolve({
                token: tokenResult,
                uid: userAuth.uid,
              });
            } catch (error: any) {}
          }
          resolve(null);
        },
        reject,
      );
    });
  }

  getAuth(): FirebaseAuth.FirebaseAuth {
    return auth;
  }
}

export const firestoreAuthService = new FirestoreAuthService();

class FirestoreDataService {
  async getCollectionGroup(
    key: string,
    whereQueries?: WhereQuery[],
  ): Promise<QuerySnapshot<DocumentData>> {
    let req = getFirestore().collectionGroup(key);

    if (whereQueries && whereQueries.length) {
      whereQueries.forEach((query) => {
        req = req.where(query.key, query.whereOp, query.value);
      });
    }
    return await req.get();
  }

  async postDocToFirestore(
    path: string,
    data: any,
    options?: SetOptions,
  ): Promise<void> {
    await getFirestore()
      .doc(path)
      .set(data, options ?? {});
  }

  async getColFromFirestore(
    path: string,
    whereQueries?: WhereQuery[],
  ): Promise<QuerySnapshot<DocumentData>> {
    let req = getFirestore().collection(path);
    if (whereQueries && whereQueries.length) {
      whereQueries.forEach((query) => {
        // @ts-ignore
        req = req.where(query.key, query.whereOp, query.value);
      });
    }

    return req.get();
  }

  async updateDocToFirestore(path: string, data: any): Promise<void> {
    await getFirestore()
      .doc(path)
      .update({...data});
  }

  async getDocFromFirestore(
    path: string,
  ): Promise<DocumentData | null | undefined> {
    const doc = await getFirestore().doc(path).get();
    if (doc.exists) {
      return {
        ...doc.data(),
        id: doc.id,
      };
    }
    return null;
  }

  async deleteDocToFirestore(path: string): Promise<void> {
    await getFirestore().doc(path).delete();
  }
}

export const firestoreDataService = new FirestoreDataService();
