import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_top_right} from '../../assets/arrow_top_right.svg';

const ArrowTopRight: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={arrow_top_right} viewBox='0 0 200 200' />
  );
};

export default ArrowTopRight;
