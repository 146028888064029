import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_left_bottom} from '../../../assets/arrows/overview/arrow_left_bottom.svg';

const ArrowLeftBottom: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={arrow_left_bottom} viewBox='0 0 107 114' />
  );
};

export default ArrowLeftBottom;
