import HttpError, { ErrorWithCode } from "../models/HttpError";

export const CORRELATION_ID_HEADER = 'x-correlation-id';

export interface Logger {
    /**
    * Output debug message to console including appropriate icons
    *
    * @param msg any data to log to the console
    */
    debug(...msg: any[]): void;

    /**
     * Output info message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    info(...msg: any[]): void;

    /**
     * Output warn message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    warn(...msg: any[]): void;

    /**
     * Output error message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    error(...msg: any[]): void;
}

let loggerInstance: Logger;
export const setLoggerInstance = (logger: Logger) => {
    loggerInstance = logger;
}

export const getErrorWithCode = function (error: unknown): ErrorWithCode {
    if (error instanceof HttpError) {
        const errorWithCode: ErrorWithCode = {
            code: error.code,
            message: error.message,
            non_transient: error.nonTransient,
        };
        if (error.value) {
            errorWithCode.value = error.value;
        }
        return errorWithCode;
    } else if (error instanceof Error) {
        return {
            message: error.message,
        };
    }
    return {
        message: 'unknown error',
    };
};

export const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) {
        return error.message;
    }
    return "Unknown error";
}

export const logger: Logger = {
    debug(...msg: any[]) {
        if (loggerInstance) {
            loggerInstance.debug(...msg);
        }
    },

    info(...msg: any[]) {
        if (loggerInstance) {
            loggerInstance.info(...msg);
        }
    },

    warn(...msg: any[]) {
        if (loggerInstance) {
            loggerInstance.warn(...msg);
        }
    },

    error(...msg: any[]) {
        if (loggerInstance) {
            loggerInstance.error(...msg);
        }
    }
}




