import fetch, { Headers, RequestInit } from 'node-fetch';
import { getBusinessLine } from '../../configs/BusinessLineConfig';
import { getAppEnv } from '../../configs/SetAppEnvConfig';
import ApiSendRequestResult from '../../models/ApiSendRequestResult';
import { EnvNames } from '../../models/EnvNames';
import { HttpErrorResponse } from '../../models/HttpError';
import PixagriApplication from '../../models/PixagriApplications';
import apiService from '../ApiService';
import BaseGoogleCloudHelper from '../BaseGoogleCloudHelper';
import { logger } from '../LogService';
import { getResponseJsonNoError } from '../ResponseHelper';

export abstract class BaseApiService extends BaseGoogleCloudHelper {
    BUSINESS_LINE_PARAM_KEY = `businessLine`;
    BUSINESS_LINE_PARAM = `:${this.BUSINESS_LINE_PARAM_KEY}`;

    abstract getApplication(): PixagriApplication;

    getBaseUrl(): string {
        return `${apiService.getApiUrl(
            this.getApplication(),
            getAppEnv() as EnvNames,
        )}/api/v1/${getBusinessLine()}`;
    }

    async sendRequest<ResponseType>(
        credentials: string,
        url: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        body?: any,
        doNotLogResponse?: boolean,
    ): Promise<ApiSendRequestResult<ResponseType>> {
        logger.debug(`Send ${this.getApplication()} request: ${url}`, { body });

        const requestOptions = await this.getRequestOptions(
            credentials,
            method,
            body,
        );
        const response = await fetch(url, requestOptions);
        const responseData = (await getResponseJsonNoError(
            response,
            true,
        )) as ResponseType;
        logger.debug(
            `${this.getApplication()} response ${response.status
            } for api path ${url}`,
            doNotLogResponse
                ? {}
                : {
                    response: responseData,
                },
        );
        const error = !response.ok;
        let error_message = '';
        if (error) {
            const errorData = responseData as HttpErrorResponse;
            error_message = errorData.message;
        }
        return {
            error,
            error_message: error_message,
            url: url,
            response: responseData,
            status: response.status,
        };
    }

    async getRequestOptions(
        credentials: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        body?: any,
    ): Promise<RequestInit> {
        const headers = this.generateRequestHeader(credentials);

        const requestOptions: RequestInit = {
            method: method,
            headers: headers,
            redirect: 'follow',
        };

        if (body) {
            requestOptions.body = JSON.stringify(body);
        }
        return requestOptions;
    }

    generateRequestHeader(credentials: string): Headers {
        const header = new Headers();
        header.append('Authorization', `Bearer ${credentials}`);
        header.append('Content-Type', `application/json`);

        return header;
    }
}
