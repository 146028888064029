import {Box, Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
// @ts-ignore
import nitrogenImage from '../assets/nitrogenImage.png';
import ArrowLeftMiddle from './icons/ArrowLeftMiddle';
import ArrowRightBottom from './icons/ArrowRightBottom';
import ArrowRightMiddle from './icons/ArrowRightMiddle';
import ArrowRightTop from './icons/ArrowRightTop';
import ArrowTopLeft from './icons/ArrowTopLeft';
import ArrowTopMiddle from './icons/ArrowTopMiddle';

const NitrogenCoverHelp: React.FC = () => {
  return (
    <Box flex={1} width={1} display='flex' flexDirection={'column'}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.nitrogen.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={nitrogenImage} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-60}
            left={-65}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.nitrogen.go_to_edit' />
              </Typography>
              <ArrowTopLeft
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -20,
                  left: 160,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-70}
            left={310}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.nitrogen.cover_indicator' />
              </Typography>
              <ArrowTopMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: 10,
                  right: 170,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-85}
            right={-170}>
            <Box width={'300px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.nitrogen.fertilization_subscription' />
              </Typography>
              <ArrowRightTop
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: 50,
                  right: 40,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={100}
            right={-195}>
            <Box width={'180px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.nitrogen.chart.explain' />
              </Typography>
              <Typography variant='caption' fontWeight={'bold'} component='div'>
                <FormattedMessage id='info.nitrogen.chart.area' />
              </Typography>
              <ArrowRightMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: 170,
                  left: -20,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-60}
            left={-200}>
            <Box width={'900px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'} lineHeight={0}>
                <FormattedMessage id='info.nitrogen.dynamic_chart' />
              </Typography>
              <ArrowRightBottom
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -50,
                  right: -130,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-180}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.select_field' />
              </Typography>
              <ArrowLeftMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -50,
                  right: -90,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NitrogenCoverHelp;
