import {AxiosResponse} from 'axios';
import log from 'loglevel';
import CropsState from 'modules/crops/models/CropsState';
import {AddRotation} from 'modules/waterBalance/models/AddRotation';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {AppState} from 'shared/store';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../../../shared/actions/Common';
import api, {pixagriApiUrl} from '../../../shared/services/ApiConfig';
import {reloadFieldsSummaryAfterDelay} from '../../fields/sagas/FieldsSagas';
import actions, {
  ADD_NEW_ROTATION,
  AddNewRotationAction,
  DELETE_ROTATION,
  DeleteRotationAction,
  LOAD_ROTATIONS,
  LoadRotationsAction,
  MODIFY_ROTATION,
  ModifyRotationAction,
} from '../actions/RotationsActions';
import Rotation from '../models/Rotation';

function addNewRotationRequest(
  selectedFieldId: number,
  newRotation: AddRotation,
) {
  const rotationUrl = `${pixagriApiUrl}/fields/${selectedFieldId}/rotations`;
  return api.post(rotationUrl, newRotation);
}

function* addNewRotation(action: AddNewRotationAction) {
  try {
    yield put(fetchStart('addNewRotation'));
    const {selectedFieldId, newRotation} = action.payload;
    yield call(addNewRotationRequest, selectedFieldId, newRotation);
    yield put(fetchSuccess('addNewRotation'));

    yield call(reloadFieldsSummaryAfterDelay, 3);
  } catch (error: any) {
    yield put(fetchError('addNewRotation', error.message));
  }
}

function modifyRotationRequest(
  fieldId: number,
  rotationId: number,
  rotation: AddRotation,
) {
  const rotationUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${rotationId}`;
  return api.put(rotationUrl, rotation);
}

function* modifyRotation(action: ModifyRotationAction) {
  try {
    yield put(fetchStart('modifyRotation'));
    const {field_id, rotation} = action.payload;
    const {rotation_id, ...newValues} = rotation;
    if (rotation_id) {
      const res: AxiosResponse = yield call(
        modifyRotationRequest,
        field_id,
        rotation_id,
        newValues,
      );
      if (res.status < 300) {
        log.info(`Rotation ${rotation_id} updated`);
      } else {
        throw Error(`Fail to update ${rotation_id} with status ${res.status}`);
      }
    } else {
      log.info(`Rotation_id not found, post new rotation`);
      const res: AxiosResponse = yield call(
        addNewRotationRequest,
        field_id,
        newValues,
      );
      if (res.status < 400) {
        log.info(`Rotation posted`);
      } else {
        throw Error(`Fail to post rotation with status ${res.status}`);
      }
    }
    yield put(fetchSuccess('modifyRotation'));
  } catch (error: any) {
    yield put(fetchError('modifyRotation', error.message));
  }
}

function getRotationsFieldRequest(selectedFieldId: number) {
  const rotationUrl = `${pixagriApiUrl}/fields/${selectedFieldId}/rotations`;
  return api.get(rotationUrl);
}

function* loadRotations(action: LoadRotationsAction) {
  try {
    // @ts-ignore
    const {data}: CropsState = yield select((state: AppState) => state.crops);
    yield put(fetchStart('loadRotations'));
    const selectedFieldId = action.payload.selectedFieldId;
    if (selectedFieldId !== null) {
      const res = yield call(getRotationsFieldRequest, selectedFieldId);
      const rotationsListing = res.data as Rotation[];
      const rotationsMap: {[key: string]: Rotation} = {};
      rotationsListing.forEach((value) => {
        if (value.rotation_id !== undefined) {
          rotationsMap[value.rotation_id] = value;
          // Set the crop code if not defined
          if (!value.crop_code && value.crop_id) {
            value.crop_code = data[value.crop_id]?.crop_code;
          }
        }
      });
      yield put(actions.loadRotationsSuccess(rotationsMap));
      yield put(fetchSuccess('loadRotations'));
    }
  } catch (error: any) {
    yield put(fetchError('loadRotations', error.message));
  }
}

function deleteRotationFieldRequest(
  selectedFieldId: number | null,
  selectedRotationId: number | null,
) {
  if (selectedRotationId !== null && selectedFieldId !== null) {
    const rotationUrl = `${pixagriApiUrl}/fields/${selectedFieldId}/rotations/${selectedRotationId}`;
    return api.delete(rotationUrl);
  }
}

function* deleteRotation(action: DeleteRotationAction) {
  try {
    yield put(fetchStart('deleteRotation'));
    const selectedField = action.payload.selectedField;
    const selectedRotationId = action.payload.selectedRotationId;
    yield call(
      deleteRotationFieldRequest,
      selectedField?.field_id as number,
      selectedRotationId,
    );
    yield put(fetchSuccess('deleteRotation'));
    yield call(reloadFieldsSummaryAfterDelay);
  } catch (error: any) {
    yield put(fetchError('deleteRotation', error.message));
  }
}

export function* rotationSagas() {
  yield all([
    takeEvery(ADD_NEW_ROTATION, addNewRotation),
    takeEvery(MODIFY_ROTATION, modifyRotation),
    takeEvery(LOAD_ROTATIONS, loadRotations),
    takeEvery(DELETE_ROTATION, deleteRotation),
  ]);
}
