import {Box, Button, Typography, useTheme} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
// @ts-ignore
import LayersIcon from '@mui/icons-material/Layers';
import SatelliteIcon from '@mui/icons-material/Satellite';
import SpaIcon from '@mui/icons-material/Spa';
// @ts-ignore
import cropsImage from '../assets/images/cropsImage.png';
// @ts-ignore
import soilsImage from '../assets/images/soilsImage.png';
import ArrowBottomLeft from './icons/cropsSoils/ArrowBottomLeft';
import ArrowBottomRight from './icons/cropsSoils/ArrowBottomRight';
import ArrowTopLeft from './icons/cropsSoils/ArrowTopLeft';
import ArrowTopRight from './icons/cropsSoils/ArrowTopRight';

/**
 * Component for showing overview in the information dialog
 * Contains an image and indication for the user to understand the application
 */
const CropsSoils: React.FC = () => {
  // @ts-ignore
  const theme = useTheme();
  return (
    <Box flex={1} display='flex' flexDirection={'column'}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.cropsSoils.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={1}
          id='image-container'
          sx={{position: 'relative'}}>
          <Box display={'flex'} width={1} justifyContent='space-evenly'>
            <img src={cropsImage} alt='nitrogen' width={'45%'} />
            <img src={soilsImage} alt='nitrogen' width={'45%'} />
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-70}
            left={150}>
            <Box width={'270px'} position='relative'>
              <Box
                display={'flex'}
                flexDirection='column'
                alignItems={'center'}>
                <Box display={'flex'}>
                  <Button sx={{cursor: 'default'}} color='inherit'>
                    <SatelliteIcon
                      fontSize='small'
                      sx={{padding: theme.spacing(0)}}
                    />
                  </Button>
                  <Button
                    sx={{cursor: 'default'}}
                    variant='contained'
                    color='primary'>
                    <SpaIcon
                      fontSize='small'
                      sx={{padding: theme.spacing(0)}}
                    />
                  </Button>
                  <Button sx={{cursor: 'default'}} color='inherit'>
                    <LayersIcon
                      fontSize='small'
                      sx={{padding: theme.spacing(0)}}
                    />
                  </Button>
                </Box>
                <Typography
                  paddingY={3}
                  variant='body2'
                  fontWeight={'bold'}
                  textAlign='center'>
                  <FormattedMessage id='info.cropsSoils.crops_list' />
                </Typography>
              </Box>
              <ArrowTopLeft
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 10,
                  left: -50,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-70}
            right={120}>
            <Box width={'270px'} position='relative'>
              <Box display={'flex'}>
                <Button sx={{cursor: 'default'}} color='inherit'>
                  <SatelliteIcon
                    fontSize='small'
                    sx={{padding: theme.spacing(0)}}
                  />
                </Button>
                <Button sx={{cursor: 'default'}} color='inherit'>
                  <SpaIcon fontSize='small' sx={{padding: theme.spacing(0)}} />
                </Button>
                <Button
                  sx={{cursor: 'default'}}
                  variant='contained'
                  color='primary'>
                  <LayersIcon
                    fontSize='small'
                    sx={{padding: theme.spacing(0)}}
                  />
                </Button>
              </Box>
              <Typography
                paddingY={3}
                variant='body2'
                fontWeight={'bold'}
                textAlign='center'>
                <FormattedMessage id='info.cropsSoils.soils_list' />
              </Typography>
              <ArrowTopRight
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 10,
                  right: -30,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-100}
            left={0}>
            <Box width={'500px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.cropsSoils.crops.explanation' />
              </Typography>
              <Typography
                variant='caption'
                fontWeight={'bold'}
                component='div'
                display={'flex'}
                alignItems='center'>
                <FormattedMessage id='info.cropsSoils.crops.complement' />
              </Typography>
              <ArrowBottomLeft
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -10,
                  right: -70,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-145}
            right={50}>
            <Box width={'500px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.cropsSoils.soils.explanation' />
              </Typography>
              <ArrowBottomRight
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -15,
                  right: -40,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CropsSoils;
