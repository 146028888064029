import {itIT} from '@mui/material/locale';
import {adminReportTranslationsIt} from 'modules/admin/report/translations/AdminReportTranslationsIt';
import {cropsTranslationsIt} from 'modules/crops/translations/CropsTranslationsIt';
import {itReportTranslations} from 'modules/report/translations/ItReportTranslations';
import {soilsTranslationsIt} from 'modules/soils/translations/SoilsTranslationsIt';
import saMessages from '../locales/it_IT.json';

const itLang = {
  messages: {
    ...saMessages,
    ...cropsTranslationsIt,
    ...soilsTranslationsIt,
    ...itReportTranslations,
    ...adminReportTranslationsIt,
  },
  muiLocale: itIT,
  locale: 'it-IT',
};
export default itLang;
