import {WeatherStationResponseWithCredentials} from 'modules/weather/models/WeatherStationResponse';

/**
 * Authenticated user info including role and SWC2 thresholds
 */
export interface AuthUser {
  uid: string;
  username?: string;
  email?: string;
  organism?: string;
  role?: string;
  photoURL?: string;
  firstname?: string;
  lastname?: string;
  threshold_high?: number;
  threshold_medium?: number;
  alert_sms?: boolean;
  alert_mail?: boolean;
  connected_stations_provider?: WeatherStationResponseWithCredentials[];
  user_type?: UserType;
}

export enum UserType {
  OPERATOR = 'operator',
  // Name "enduser" beacause "end_user" is'nt accepted by cloud function's Firebase
  END_USER = 'enduser',

  /**
   * Temporary solution to open som functionnalities for some managers on admin panel
   * In time, prefer use user access roles when available
   */
  CUSTOMER_MANAGER = 'customer_manager',
}
