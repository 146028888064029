import {generatePath} from 'react-router-dom';
import {ServiceView} from 'shared/models/RouterParam';
import {AdminScope} from '../models/AdminScope';

export const indexPath = '/admin';

export const adminScopeParamKey = 'adminScope';
export const adminScopeParam = `:${adminScopeParamKey}`;

export const serviceViewParamKey = 'viewId';
export const serviceViewParam = `:${serviceViewParamKey}`;

export const selectedAdminScopeRoutePath = `${indexPath}/${adminScopeParam}`;
export const selectedAdminScopeFullRoutePath = `${selectedAdminScopeRoutePath}/${serviceViewParam}`;

/**
 * reg split
 * @param url
 * @param adminScope
 * @returns
 */
export const replaceAdminScopePath = (
  url: string,
  adminScope: AdminScope,
): string => {
  if (Object.values(AdminScope).every((value) => !url.includes(value))) {
    return url + '/' + adminScope;
  }
  return url.replace(/^(.*\/admin\/)([^\/]+)(\/.*)$/, `$1${adminScope}$3`);
};

export const getAdminScopeRootPath = (
  adminScope: AdminScope,
  view: ServiceView,
): string => {
  return selectedAdminScopeFullRoutePath
    .replace(adminScopeParam, adminScope)
    .replace(serviceViewParam, view);
};

export const getSelectedAdminScopeFullRoute = (
  adminScope: AdminScope,
  serviceView: string,
): string => {
  const url = generatePath(selectedAdminScopeFullRoutePath, {
    adminScope,
    viewId: serviceView,
  });
  return url;
};
