import { EnvNames } from "../models/EnvNames";
import { logger } from "../services/LogService";

let appEnv = process.env.APP_ENV || process.env.APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT_NAME || process.env.ENV || EnvNames.DEV as EnvNames
export const setAppEnv = (envName: EnvNames) => {
    logger.info(`App env set to ${envName}`);
    appEnv = envName;
};

export const getAppEnv = () => {
    return appEnv
}

