import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as VegetationIconTab} from '../../assets/vegetationIcon.svg';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean;
}

const VegetationIcon: React.FC<HeaderIconProps> = (props: HeaderIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={VegetationIconTab}
      style={{fillOpacity: props.selected ? '1' : '0.5'}}
      viewBox='0 0 22 16'
    />
  );
};

export default VegetationIcon;
