import React from 'react';
import {Redirect} from 'react-router-dom';
import {createRoutes} from '../@crema/utility/Utils';
import {errorPagesConfigs} from '../shared/components/errorPages';
import {initialUrl} from '../shared/configs/AppConst';
import {adminRouterConfigs} from './admin/configs/AdminRouterConfigs';
import {authRouteConfigs} from './auth/AuthRouter';
import {campaignsRouteConfigs} from './campaigns/CampaignsRouter';
import {cropsRouteConfigs} from './crops/CropsRouter';
import {dashboardRouterConfigs} from './dashboards/DashboardRouter';
import {fieldsRouteConfigs} from './fields/FieldsRouter';
import {soilsRouteConfigs} from './soils/SoilsRouter';

const routeConfigs = [
  ...authRouteConfigs,
  ...campaignsRouteConfigs,
  ...fieldsRouteConfigs,
  ...errorPagesConfigs,
  ...cropsRouteConfigs,
  ...soilsRouteConfigs,
  ...dashboardRouterConfigs,
  ...adminRouterConfigs,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: (): React.ReactElement => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
