export const adminReportTranslationsEs = {
  'admin.report.raw_data.title': 'Datos en bruto',
  'admin.report.vizualization.title': 'Visualización',
  'admin.report.path.report_not_found': 'Informe no encontrado',
  'admin.report.modal.title': 'Informe',
  'admin.report.status.success': 'Éxito',
  'admin.report.status.sent': 'Enviado',
  'admin.report.status.confirmed': 'Confirmado',
  'admin.report.status.sending': 'Enviando',
  'admin.report.status.failed': 'Fallido',
  'admin.report.status.error': 'Error',
  'admin.report.status.pending': 'Pendiente',
  'admin.report.status.processing': 'En proceso',
  'admin.report.status.unknown': 'Desconocido',
  'admin.report.back_to_list': 'Volver a la lista',
  'admin.report.title': 'Informe',
  'admin.report.notifications.status': 'Estado',
  'admin.report.notifications.notification_channel': 'Canal de notificación',
  'admin.report.notifications.created_at': 'Fecha de creación',
  'admin.report.notifications.updated_at': 'Fecha de actualización',
  'admin.report.notifications.sended_at': 'Fecha de envío',
  'admin.report.notifications.title': 'Notificaciones',
  'admin.report.notifications.user': 'Usuario',
  'admin.report.notifications.recipient': 'Destinatario',
};
