export const adminReportTranslationsIt = {
  'admin.report.raw_data.title': 'Dati grezzi',
  'admin.report.vizualization.title': 'Visualizzazione',
  'admin.report.path.report_not_found': 'Rapporto non trovato',
  'admin.report.modal.title': 'Rapporto',
  'admin.report.status.success': 'Successo',
  'admin.report.status.sent': 'Inviato',
  'admin.report.status.confirmed': 'Confermato',
  'admin.report.status.sending': 'Invio in corso',
  'admin.report.status.failed': 'Fallito',
  'admin.report.status.error': 'Errore',
  'admin.report.status.pending': 'In attesa',
  'admin.report.status.processing': 'In elaborazione',
  'admin.report.status.unknown': 'Sconosciuto',
  'admin.report.back_to_list': 'Torna alla lista',
  'admin.report.title': 'Rapporto',
  'admin.report.notifications.status': 'Stato',
  'admin.report.notifications.notification_channel': 'Canale di notifica',
  'admin.report.notifications.created_at': 'Data di creazione',
  'admin.report.notifications.updated_at': 'Data di aggiornamento',
  'admin.report.notifications.sended_at': 'Data di invio',
  'admin.report.notifications.title': 'Notifiche',
  'admin.report.notifications.user': 'Utente',
  'admin.report.notifications.recipient': 'Destinatario',
};
