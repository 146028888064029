import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as cumulative_arrow_top} from '../../assets/cumulative_arrow_top.svg';

const CumulativeArrowTop: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={cumulative_arrow_top}
      viewBox='0 0 220 219'
    />
  );
};

export default CumulativeArrowTop;
