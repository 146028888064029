import ContextProvider from '@crema/utility/ContextProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import AuthRoutes from 'shared/utils/AuthRoutes';
import LocaleProvider from 'shared/utils/LocaleProvider';
import AppRootComponent from './AppRootComponent';

import CssBaseline from '@mui/material/CssBaseline';
import {setBusinessLine} from 'library/src/core/configs/BusinessLineConfig';
import TerranisBusinessLines from 'library/src/core/models/TerranisBusinessLines';
import React from 'react';
import ErrorBoundary from 'shared/utils/ErrorBoundary';
import LocalizationProviderComponent from 'shared/utils/LocalizationProviderComponent';
import configureStore, {history} from './shared/store';
const store = configureStore();

/**
 * Application entry point
 */
setBusinessLine(TerranisBusinessLines.AGRI);
const App: React.FC = () => (
  <ContextProvider>
    <Provider store={store}>
      <CremaThemeProvider>
        <CremaStyleProvider>
          <LocaleProvider>
            <LocalizationProviderComponent>
              <ConnectedRouter history={history}>
                <ErrorBoundary>
                  <AuthRoutes>
                    <CssBaseline />
                    <AppRootComponent />
                  </AuthRoutes>
                </ErrorBoundary>
              </ConnectedRouter>
            </LocalizationProviderComponent>
          </LocaleProvider>
        </CremaStyleProvider>
      </CremaThemeProvider>
    </Provider>
  </ContextProvider>
);

export default App;
