import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import {AppBar, Hidden} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import AdminHeaderButton from 'modules/admin/components/AdminHeaderButton';
import {UserType} from 'modules/auth/models/AuthUser';
import CarbonHeaderButton from 'modules/carbon/components/CarbonHeaderButton';
import NitrogenHeaderButton from 'modules/nitrogen/components/NitrogenHeaderButton';
import VegetationHeaderButton from 'modules/vegetation/components/VegetationHeaderButton';
import IrrigationHeaderButton from 'modules/waterBalance/components/IrrigationHeaderButton';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {onToggleAppDrawer, setHeaderHeight} from 'shared/actions/Common';
import {AppState} from 'shared/store';
import UserHeaderButton from '../../../modules/auth/components/UserHeaderButton';
import LanguageSwitcher from '../LanguageSwitcher';
import useStyles from './AppHeader.style';
import HeaderLogo from './HeaderLogo';

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const appBarRef = useRef<any>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const {customer} = useSelector<AppState, AppState['auth']>(
    (state) => state.auth,
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}>
      <MenuItem onClick={handleMobileMenuClose}>
        <NitrogenHeaderButton alwaysShowTitle />
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <CarbonHeaderButton alwaysShowTitle />
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <VegetationHeaderButton alwaysShowTitle />
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <IrrigationHeaderButton alwaysShowTitle />
      </MenuItem>
      {/* <MenuItem onClick={handleMobileMenuClose}>
        <WeatherHeaderButton alwaysShowTitle />
      </MenuItem> */}
      <MenuItem>
        <UserHeaderButton
          alwaysShowTitle
          handleMobileMenuClose={handleMobileMenuClose}
        />
      </MenuItem>
    </Menu>
  );

  useLayoutEffect(() => {
    if (appBarRef.current) {
      dispatch(setHeaderHeight(appBarRef.current.offsetHeight));
    }
  }, [dispatch]);

  return (
    <>
      <AppBar
        ref={appBarRef}
        color='primary'
        className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(onToggleAppDrawer())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <Box className={classes.sectionDesktop}>
            <Box>
              <HeaderLogo />
            </Box>
            <Box>
              <IrrigationHeaderButton />
              <VegetationHeaderButton />
              <NitrogenHeaderButton />
              <CarbonHeaderButton />
              {/* <WeatherHeaderButton /> */}
            </Box>
            <Box display={'flex'}>
              <UserHeaderButton />
            </Box>
          </Box>
          {customer &&
            customer.user_type &&
            (customer.user_type === UserType.CUSTOMER_MANAGER ||
              customer.user_type === UserType.OPERATOR) && (
              <Box borderLeft={1} borderRight={1}>
                <AdminHeaderButton />
              </Box>
            )}
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'>
              <MoreIcon />
            </IconButton>
          </Box>
          <LanguageSwitcher localeOnly />
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};
export default AppHeader;
