import sharedNotificationSaga from 'library/src/notifications/redux/NotificationsSaga';
import {sharedReportsSagas} from 'library/src/report/redux/SharedReportSagas';
import {carbonSaga} from 'modules/carbon/sagas/CarbonSaga';
import {nitrogenSaga} from 'modules/nitrogen/sagas/NitrogenSaga';
import {productsSagas} from 'modules/products/saga/ProductsSaga';
import {reportSaga} from 'modules/report/redux/ReportSaga';
import {subscriptionsSaga} from 'modules/subscriptions/saga/SubscriptionsSaga';
import {vegetationSagas} from 'modules/vegetation/sagas/VegetationSagas';
import {weatherSagas} from 'modules/weather/sagas/WeatherSagas';
import {all} from 'redux-saga/effects';
import {authSagas} from '../../modules/auth/sagas/AuthSagas';
import {campaignsSagas} from '../../modules/campaigns/sagas/CampaignsSagas';
import {cropsSagas} from '../../modules/crops/sagas/CropsSagas';
import {dashBoardSaga} from '../../modules/dashboards/sagas/DashboardSaga';
import {fieldsSagas} from '../../modules/fields/sagas/FieldsSagas';
import {rotationSagas} from '../../modules/rotations/sagas/RotationSagas';
import {soilsSagas} from '../../modules/soils/sagas/SoilsSagas';
import {sysIrrigationSagas} from '../../modules/sysIrrigation/sagas/SysIrrigationSagas';
import {waterBalanceSagas} from '../../modules/waterBalance/sagas/WaterBalanceSagas';

export function* rootSagas() {
  yield all([
    authSagas(),
    fieldsSagas(),
    soilsSagas(),
    cropsSagas(),
    waterBalanceSagas(),
    sysIrrigationSagas(),
    campaignsSagas(),
    rotationSagas(),
    productsSagas(),
    subscriptionsSaga(),
    dashBoardSaga(),
    carbonSaga(),
    nitrogenSaga(),
    weatherSagas(),
    reportSaga(),
    sharedReportsSagas(),
    vegetationSagas(),
    sharedNotificationSaga(),
  ]);
}
