import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean;
}

const AdminIcon: React.FC<HeaderIconProps> = (props: HeaderIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={BuildCircleIcon}
      style={{fillOpacity: props.selected ? '1' : '0.5'}}
    />
  );
};

export default AdminIcon;
