import {loadStripe} from '@stripe/stripe-js';
import axios, {AxiosRequestConfig} from 'axios';
import log from 'loglevel';
import {getServiceOverviewFullRoute} from 'modules/fields/configs/FieldsRoutePaths';
import {fetchError, fetchStart, fetchSuccess, redirectTo} from 'shared/actions';
import {getTerranisSharedApiEndpoint} from 'shared/configs/FirebaseConfig';
import {ServiceId} from 'shared/models/RouterParam';
import {auth, firestore} from 'shared/services/ReduxSagaFirebase';
import {subscriptionActions} from '../actions/SubscriptionsActions';
import {
  QUERY_PAYMENT_ALREADY_EXISTING_TRIAL,
  QUERY_PAYMENT_SUCCESS,
  STRIPE_PUBLISHABLE_KEY,
} from '../configs/constant';
import CheckoutSession from '../models/CheckoutSession';
import SubscriptionPaymentMethod from '../models/SubscriptionPaymentMethod';

export async function handleStripeSubscription(
  checkoutSession: CheckoutSession,
  paymentMethod: SubscriptionPaymentMethod,
  dispatch: any,
  token: string,
  service: ServiceId,
) {
  if (
    checkoutSession.metadata.product_type === 'trial' ||
    paymentMethod === SubscriptionPaymentMethod.INVOICE
  ) {
    log.debug(`No payment required`);
    try {
      dispatch(fetchStart('stripe_susbscribe'));
      //@ts-ignore
      const options: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const body = JSON.stringify(checkoutSession);
      const url = `${getTerranisSharedApiEndpoint()}/subscriptions/stripe_subscribe`;

      const {data} = await axios.post(url, body, options);

      log.debug(data);
      if (data && data.subscriptionId) {
        dispatch(subscriptionActions.loadSubscriptions());
        dispatch(fetchSuccess('stripe_susbscribe'));
        dispatch(
          redirectTo(
            `${getServiceOverviewFullRoute(
              checkoutSession.metadata.campaign_id,
              service,
            )}${QUERY_PAYMENT_SUCCESS}`,
          ),
        );
      } else {
        throw new Error(`Invalid subscription response`);
      }
    } catch (error: any) {
      if (error.response) {
        const {data} = error.response;
        if (data && data.code && data.code === 'ALREADY_EXISTING_TRIAL') {
          dispatch(
            redirectTo(
              `${getServiceOverviewFullRoute(
                checkoutSession.metadata.campaign_id,
                service,
              )}${QUERY_PAYMENT_ALREADY_EXISTING_TRIAL}`,
            ),
          );
        }
        dispatch(fetchSuccess('stripe_susbscribe'));
        return;
      }
      log.error(error);
      dispatch(
        redirectTo(
          `${getServiceOverviewFullRoute(
            checkoutSession.metadata.campaign_id,
            service,
          )}`,
        ),
      );
      dispatch(fetchError('stripe_susbscribe', error.message));
    }
  } else {
    log.info(`Redirect to stripe`);
    dispatch(fetchStart('controlAndSendCheckoutSession'));
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    const currentUser = auth.currentUser?.uid;
    log.info(`Current user: ${currentUser}`);
    if (currentUser) {
      const docRef = await firestore
        .collection('customers')
        .doc(currentUser)
        .collection('checkout_sessions')
        .add(checkoutSession);
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const checkoutData: any = snap.data() as any;
        const {error, sessionId} = checkoutData;
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          // TODO: use intel for error message
          dispatch(
            fetchError(
              'controlAndSendCheckoutSession',
              `An error occured: ${error.message}`,
            ),
          );
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          stripe?.redirectToCheckout({sessionId});
        }
      });
    }
  }
}
