import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as Question} from '../../../assets/images/icons/questionIcon.svg';

const QuestionIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return <SvgIcon {...props} component={Question} viewBox='0 0 24 24' />;
};

export default QuestionIcon;
