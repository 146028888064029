import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as Info} from '../../../assets/images/icons/infoIcon.svg';

const InfoIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return <SvgIcon {...props} component={Info} viewBox='0 0 24 24' />;
};

export default InfoIcon;
