import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
//@ts-ignore
import {ReactComponent as PastDueSubscription} from '../../assets/past_due_subscription.svg';

const PastDueSubscriptionIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps,
) => {
  return (
    <SvgIcon {...props} component={PastDueSubscription} viewBox='0 0 83 100' />
  );
};

export default PastDueSubscriptionIcon;
