import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_top_left} from '../../assets/arrow_top_left.svg';

const ArrowTopLeft: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={arrow_top_left} viewBox='0 0 278 280' />
  );
};

export default ArrowTopLeft;
