import React from 'react';
import {indexPath} from './AdminRoutePath';

export const adminRouterConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: indexPath,
        component: React.lazy(() => import('../AdminRouter')),
      },
    ],
  },
];
