import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as weather_arrow_top} from '../../assets/weather_arrow_top.svg';

const WeatherArrowTop: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} component={weather_arrow_top} viewBox='0 0 179 179' />
  );
};

export default WeatherArrowTop;
