import {AppStatus} from '../../core/models/AppStatus';

// action strings
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';

export interface FetchStartAction {
  type: typeof FETCH_START;
  fetchId: string;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
  fetchId: string;
}

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  fetchId: string;
  error: string;
}

export const SAVE_DEVICE_DIMENSIONS = 'SAVE_DEVICE_DIMENSIONS';
export interface SaveDeviceDimensionAction {
  type: typeof SAVE_DEVICE_DIMENSIONS;
  payload: {
    isTablet?: boolean;
    width: number;
    height: number;
  };
}

export const TOGGLE_OPEN_DRAWER = 'TOGGLE_OPEN_DRAWER';
export interface ToggleOpenDrawerAction {
  type: typeof TOGGLE_OPEN_DRAWER;
  payload: boolean;
}

export const SET_CURRENT_LOCAL = 'SET_CURRENT_LOCAL';
export interface SetCurrentLocalAction {
  type: typeof SET_CURRENT_LOCAL;
  payload: string;
}

export const SET_APP_INFOS = 'SET_APP_INFOS';
export interface SetAppInfosAction {
  type: typeof SET_APP_INFOS;
  payload: {
    app_status?: AppStatus;
    terms_and_conditions_url?: string;
  };
}

/**
 * Oenoview web project legacy
 */
export const REDIRECT_TO = 'REDIRECT_TO';
export interface RedirectToAction {
  type: typeof REDIRECT_TO;
  redirectTo: string;
}

/**
 * Oenoview web project legacy
 */
export const HEADER_HEIGHT = 'HEADER_HEIGHT';
export interface HeaderHeightAction {
  type: typeof HEADER_HEIGHT;
  value: number;
}

export type SharedCommonActionTypes =
  | FetchStartAction
  | FetchSuccessAction
  | FetchErrorAction
  | SaveDeviceDimensionAction
  | ToggleOpenDrawerAction
  | SetCurrentLocalAction
  | SetAppInfosAction
  | RedirectToAction
  | HeaderHeightAction;

export const sharedCommonActions = {
  setAppInfos: (
    status?: AppStatus,
    termsAndConditionsUrl?: string,
  ): SetAppInfosAction => {
    return {
      type: SET_APP_INFOS,
      payload: {
        app_status: status,
        terms_and_conditions_url: termsAndConditionsUrl,
      },
    };
  },

  setCurrentLocal: (local: string): SetCurrentLocalAction => {
    return {
      type: SET_CURRENT_LOCAL,
      payload: local,
    };
  },
  toggleOpenDrawer: (open: boolean): ToggleOpenDrawerAction => {
    return {
      type: TOGGLE_OPEN_DRAWER,
      payload: open,
    };
  },
  fetchStart: (fetchId: string): FetchStartAction => {
    return {
      type: FETCH_START,
      fetchId: fetchId,
    };
  },

  fetchSuccess: (fetchId: string): FetchSuccessAction => {
    return {
      type: FETCH_SUCCESS,
      fetchId: fetchId,
    };
  },

  fetchError: (fetchId: string, error: string): FetchErrorAction => {
    return {
      type: FETCH_ERROR,
      fetchId: fetchId,
      error: error,
    };
  },
  saveDimension: (
    width: number,
    height: number,
    isTablet?: boolean,
  ): SaveDeviceDimensionAction => {
    return {
      type: SAVE_DEVICE_DIMENSIONS,
      payload: {
        width: width,
        height: height,
        isTablet: isTablet,
      },
    };
  },
  redirectTo: (redirectTo: string): RedirectToAction => {
    return {
      type: REDIRECT_TO,
      redirectTo,
    };
  },
  setHeaderHeight: (value: number): HeaderHeightAction => {
    return {
      type: HEADER_HEIGHT,
      value: value,
    };
  },
};
