import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
// @ts-ignore
import LayersIcon from '@mui/icons-material/Layers';
import SatelliteIcon from '@mui/icons-material/Satellite';
import SpaIcon from '@mui/icons-material/Spa';
// @ts-ignore
import overviewImage from '../assets/images/overviewImage.jpg';
import ArrowLeftBottom from './icons/overview/ArrowLeftBottom';
import ArrowLeftMiddleBottom from './icons/overview/ArrowLeftMiddleBottom';
import ArrowLeftMiddleTop from './icons/overview/ArrowLeftMiddleTop';
import ArrowLeftTop from './icons/overview/ArrowLeftTop';
import ArrowRightMiddle from './icons/overview/ArrowRightMiddle';
import ArrowTopMiddle from './icons/overview/ArrowTopMiddle';

/**
 * Component for showing overview in the information dialog
 * Contains an image and indication for the user to understand the application
 */
const Overview: React.FC = () => {
  const theme = useTheme();
  return (
    <Box flex={1} width={1} display='flex' flexDirection={'column'}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.overview.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={overviewImage} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-35}
            right={50}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.overview.service_navigation' />
              </Typography>
              <ArrowTopMiddle
                sx={{
                  fontSize: '80px',
                  position: 'absolute',
                  top: -15,
                  left: -70,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={220}
            right={-200}>
            <Box width={'170px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.overview.map.explanation' />
              </Typography>
              <Typography variant='caption' component='div'>
                <FormattedMessage id='info.overview.on_click' />
              </Typography>
              <ArrowRightMiddle
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 0,
                  left: -120,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={0}
            left={-180}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.overview.navigation.explanation' />
              </Typography>
              <Typography
                variant='caption'
                fontWeight={'bold'}
                component='div'
                display={'flex'}
                alignItems='center'>
                <SatelliteIcon
                  color='primary'
                  sx={{padding: theme.spacing(1)}}
                />
                <FormattedMessage id='info.overview.navigation.field' />
              </Typography>
              <Typography
                variant='caption'
                fontWeight={'bold'}
                component='div'
                display={'flex'}
                alignItems='center'>
                <SpaIcon color='primary' sx={{padding: theme.spacing(1)}} />
                <FormattedMessage id='info.overview.navigation.crops' />
              </Typography>
              <Typography
                variant='caption'
                fontWeight={'bold'}
                component='div'
                display={'flex'}
                alignItems='center'>
                <LayersIcon color='primary' sx={{padding: theme.spacing(1)}} />
                <FormattedMessage id='info.overview.navigation.soils' />
              </Typography>
              <ArrowLeftTop
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -10,
                  right: -70,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={130}
            left={-170}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.overview.overview_item_list' />
              </Typography>
              <ArrowLeftMiddleTop
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -20,
                  right: -90,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-175}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.field_item_list' />
              </Typography>
              <Typography variant='caption' component='div'>
                <FormattedMessage id='info.field_item_list.subscribe' />
              </Typography>
              <ArrowLeftMiddleBottom
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -60,
                  right: -40,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-70}
            left={-175}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.add_field' />
              </Typography>
              <ArrowLeftBottom
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -80,
                  right: 5,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
