import {Reducer} from 'redux';
import {
  CampaignsTypes,
  LOAD_CAMPAIGNS,
  LOAD_CAMPAIGNS_SUCCESS,
} from '../actions/CampaignsActions';
import CampaignsState from '../models/CampaignsState';

const initialState: CampaignsState = {
  data: {},
};

const campaignsReducers: Reducer<CampaignsState, CampaignsTypes> = (
  state: CampaignsState = initialState,
  action: CampaignsTypes,
) => {
  switch (action.type) {
    case LOAD_CAMPAIGNS:
      return {
        ...state,
      };
    case LOAD_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default campaignsReducers;
