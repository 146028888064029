import sharedNotificationsReducer from 'library/src/notifications/redux/NotificationsReducer';
import sharedReportReducers from 'library/src/report/redux/SharedReportReducer';
import ProductsReducers from 'modules/products/reducers/ProductsReducers';
import reportReducer from 'modules/report/redux/ReportReducers';
import SubscriptionsReducers from 'modules/subscriptions/reducers/SubscriptionsReducers';
import WeatherReducers from 'modules/weather/reducers/WeatherReducers';
import AuthReducers from '../../modules/auth/reducers/AuthReducers';
import CampaignsReducers from '../../modules/campaigns/reducers/CampaignsReducers';
import CarbonReducers from '../../modules/carbon/reducers/CarbonReducers';
import CropsReducers from '../../modules/crops/reducers/CropsReducers';
import FieldsReducers from '../../modules/fields/reducers/FieldsReducers';
import NitrogenReducers from '../../modules/nitrogen/reducers/NitrogenReducers';
import RotationsReducers from '../../modules/rotations/reducers/RotationsReducers';
import SoilsReducers from '../../modules/soils/reducers/SoilsReducers';
import SysIrrigationReducers from '../../modules/sysIrrigation/reducers/SysIrrigationReducers';
import VegetationReducers from '../../modules/vegetation/reducers/VegetationReducers';
import WaterBalanceReducers from '../../modules/waterBalance/reducers/WaterBalanceReducers';
import Common from './Common';
import Settings from './Setting';

const reducers = {
  settings: Settings,
  auth: AuthReducers,
  fields: FieldsReducers,
  common: Common,
  crops: CropsReducers,
  soils: SoilsReducers,
  waterBalance: WaterBalanceReducers,
  sysIrrigation: SysIrrigationReducers,
  campaigns: CampaignsReducers,
  rotations: RotationsReducers,
  products: ProductsReducers,
  subscriptions: SubscriptionsReducers,
  carbon: CarbonReducers,
  nitrogen: NitrogenReducers,
  vegetation: VegetationReducers,
  weather: WeatherReducers,
  reportOld: reportReducer,
  report: sharedReportReducers,
  notifications: sharedNotificationsReducer,
};

export default reducers;
