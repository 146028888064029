enum PixagriScopes {

    /**
     * Common models/functions shared by several scopes
     *
     */
    CORE = "core",

    /**
     * Authentication
     */
    AUTHENTICATION = "authentication",

    DISTRIBUTORS = "distributors",

    PRODUCERS = "producers",

    PROCESSINGS = "processings",

    INDICATORS = "indicators",

    ORGANIZATIONS = "organizations",

    CONTRACTS = "contracts",

    CUSTOMERS = "customers",

    FIELDS = "fields",

    STATISTICS = "statistics",

    CAMPAIGN = "campaign",

    USERACCESSES = "userAccesses",

    NOTES = "notes",

    REPORT = "report",

    REPORT_JOB = "report-job",

    REPORT_WORKER = "report-worker",

    NOTIFICATION = "notifications",
    PROCESSINGS_ENGINES = "processings-engines",
    USERS = "users",
    CROPS = "crops",
    SOILS = "soils",
}

export default PixagriScopes;