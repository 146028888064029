import '@firebase/auth';
import '@firebase/firestore';
import firebase from 'firebase/app';
import {
  setFirebaseAuth,
  setFirestore,
} from 'library/src/core/services/FirestoreService';
import ReduxSagaFirebase from 'redux-saga-firebase';
import firebaseConfig from 'shared/configs/FirebaseConfig';

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

export const auth = firebaseApp.auth();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
console.log('set firestore');
console.log('set auth');
setFirebaseAuth(firebase.auth() as any);

export const firestore = firebaseApp.firestore();
setFirestore(firestore);
const rsf = new ReduxSagaFirebase(firebaseApp);

export default rsf;
