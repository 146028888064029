import {frFR} from '@mui/material/locale';
import {adminReportTranslationsFr} from 'modules/admin/report/translations/AdminReportTranslationsFr';
import {cropsTranslationsFr} from 'modules/crops/translations/CropsTranslationsFr';
import {frReportTranslations} from 'modules/report/translations/FrReportTranslations';
import {soilsTranslationsFr} from 'modules/soils/translations/SoilsTranslationsFr';
import saMessages from '../locales/fr_FR.json';

const saLang = {
  messages: {
    ...saMessages,
    ...cropsTranslationsFr,
    ...soilsTranslationsFr,
    ...frReportTranslations,
    ...adminReportTranslationsFr,
  },
  muiLocale: frFR,
  locale: 'fr-FR',
};
export default saLang;
