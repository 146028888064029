import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_left_middle_top} from '../../../assets/arrows/overview/arrow_left_middle_top.svg';

const ArrowLeftMiddleTop: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={arrow_left_middle_top}
      viewBox='0 0 113 101'
    />
  );
};

export default ArrowLeftMiddleTop;
