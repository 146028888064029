import { DateTime } from 'luxon';

export default abstract class BaseGoogleCloudHelper {
    getFirebaseConsoleUrl(projectId: string, documentPath: string) {
        return `https://console.firebase.google.com/u/0/project/${projectId}/firestore/data/${encodeURIComponent(
            documentPath,
        )}`;
    }

    getStorageConsoleUrl(projectId: string, storagePath: string) {
        return `https://console.cloud.google.com/storage/browser/${storagePath};tab=objects?project=${projectId}`;
    }

    getLogConsoleUrl = (projectId: string, query: string, startDate?: Date, hoursDuration = 4) => {
        let logConsoleUrl = `https://console.cloud.google.com/logs/query;query=${encodeURI(query)}`;
        if (startDate) {
            const startDateTime = DateTime.fromJSDate(startDate).minus({
                minute: 10,
            });
            const endDateTime = startDateTime.plus({ hours: hoursDuration });
            const timeRange = `timeRange=${startDateTime.toJSDate().toISOString()}%2F${endDateTime
                .toJSDate()
                .toISOString()}`;
            logConsoleUrl = `${logConsoleUrl};${timeRange}`;
        }
        logConsoleUrl = `${logConsoleUrl}?project=${projectId}`;
        return logConsoleUrl;
    };
}
