import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as ArrowSoils} from '../../../assets/images/icons/arrowSoils.svg';

const ArrowSoilsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return <SvgIcon {...props} component={ArrowSoils} viewBox='0 0 95 42' />;
};

export default ArrowSoilsIcon;
