import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as cumulative_arrow_top_left} from '../../assets/cumulative_arrow_top_left.svg';

const CumulativeArrowTopLeft: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={cumulative_arrow_top_left}
      viewBox='0 0 264 265'
    />
  );
};

export default CumulativeArrowTopLeft;
