import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_right_middle_top} from '../../assets/arrow_right_middle_top.svg';

const ArrowRightMiddleTop: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={arrow_right_middle_top}
      viewBox='0 0 78 56'
    />
  );
};

export default ArrowRightMiddleTop;
