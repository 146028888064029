import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
// @ts-ignore
import week_image from '../assets/week_image.png';
import WeekArrowTop from './icons/WeekArrowTop';

const WeekHelp: React.FC = () => {
  const theme = useTheme();
  return (
    <Box flex={1} width={1} display='flex' flexDirection={'column'}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.weather.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={week_image} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-100}
            left={320}>
            <Box width={'250px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage
                  id='info.weather.week.selector'
                  values={{
                    b: (str: string) => (
                      <Typography
                        variant='caption'
                        fontWeight='bold'
                        display='block'>
                        {str}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
              <WeekArrowTop
                sx={{
                  fontSize: '200px',
                  position: 'absolute',
                  top: 30,
                  left: -140,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Typography
          width='690px'
          variant='caption'
          color={theme.palette.grey[600]}
          paddingY={3}
          textAlign='center'>
          <FormattedMessage id='info.weather.week.explaination' />
        </Typography>
      </Box>
    </Box>
  );
};

export default WeekHelp;
