import {getAppEnv} from '../configs/SetAppEnvConfig';
import {EnvNames} from '../models/EnvNames';
import PixagriApplication from '../models/PixagriApplications';
import {logger} from './LogService';

class ApiService {
  SUFFIX_API_BY_ENV: {[envName in EnvNames]?: string} = {
    [EnvNames.DEV]: 's2nygtk3nq-ew.a.run.app',
    [EnvNames.PROD]: 'ccgfqp5o5q-ew.a.run.app',
  };

  getApiUrl(applicationName: PixagriApplication, env: EnvNames) {
    let applicationUrl: string | null = null;
    if (env === EnvNames.PROD) {
      // TODO: prod url following 'https://api.terranis.fr/{applicationName}' using load balancer
    }

    logger.info('Env getApiUrl', {
      env: env,
    });
    // Default application url
    if (!applicationUrl) {
      const suffix = this.SUFFIX_API_BY_ENV[getAppEnv() as EnvNames];
      logger.info('suffix', {
        suffix: suffix,
      });

      if (!suffix) {
        throw new Error(`No suffix found for env ${getAppEnv()}`);
      }

      applicationUrl = `https://${applicationName}-${suffix}`;
    }
    logger.info('appurl', {
      appurl: applicationUrl,
    });

    return applicationUrl;
  }
}

const apiService = new ApiService();
export default apiService;
