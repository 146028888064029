import {esES} from '@mui/material/locale';
import {adminReportTranslationsEs} from 'modules/admin/report/translations/AdminReportTranslationsEs';
import {esReportTranslations} from 'modules/report/translations/EsReportTranslations';
import {soilsTranslationsEs} from 'modules/soils/translations/SoilsTranslationsEs';
import saMessages from '../locales/es_ES.json';

const esLang = {
  messages: {
    ...saMessages,
    ...soilsTranslationsEs,
    ...esReportTranslations,
    ...adminReportTranslationsEs,
  },
  muiLocale: esES,
  locale: 'es-ES',
};
export default esLang;
