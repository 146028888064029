import {ruRU} from '@mui/material/locale';
import {adminReportTranslationsUz} from 'modules/admin/report/translations/AdminReportTranslationsUz';
import {uzReportTranslations} from 'modules/report/translations/UzReportTranslations';
import uzMessages from '../locales/uz_UZ.json';

const UzLang = {
  messages: {
    ...uzMessages,
    ...uzReportTranslations,
    ...adminReportTranslationsUz,
  },
  muiLocale: ruRU,
  locale: 'uz-UZ',
};
export default UzLang;
