import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
// @ts-ignore
import {ReactComponent as arrow_left_middle_bottom} from '../../../assets/arrows/overview/arrow_left_middle_bottom.svg';

const ArrowLeftMiddleBottom: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={arrow_left_middle_bottom}
      viewBox='0 0 106 91'
    />
  );
};

export default ArrowLeftMiddleBottom;
