import { Response } from "node-fetch";
import { logger } from "./LogService";
import HttpError, { ErrorWithCode } from "../models/HttpError";

/**
 * Try to do response.json and catch the error if any. If errored, return an empty JSON {}
 * @param response
 */
export const getResponseJsonNoError = async (response: Response, fallbackText?: boolean): Promise<any> => {
  let responseText = "";
  try {
    if (fallbackText) {
      responseText = await response.text();
      return JSON.parse(responseText);
    } else {
      return await response.json();
    }
  } catch (err) {
    logger.warn(`Response is not a valid json`);
    logger.warn(err);
    if (fallbackText) {
      return { response_text: responseText };
    } else {
      return {};
    }
  }
};

export const getErrorWithCode = function (error: unknown): ErrorWithCode {
  if (error instanceof HttpError) {
    const errorWithCode: ErrorWithCode = {
      code: error.code,
      message: error.message,
    };
    if (error.value) {
      errorWithCode.value = error.value;
    }
    return errorWithCode;
  } else if (error instanceof Error) {
    return {
      message: error.message,
    };
  }
  return {
    message: "unknown error",
  };
};
